import Vue from "vue";
import App from "./App.vue";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store/store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import vuescroll from "vuescroll";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
import momentVue from "vue-moment";
import FlagIcon from "vue-flag-icon";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import VueEllipseProgress from "vue-ellipse-progress";
import fullscreen from "vue-fullscreen";
import VueHtml2Canvas from "vue-html2canvas";
import Tawk from "vue-tawk";
import * as Utils from "@/utils.js";

import "./styles/veetal_styles.scss";

import { sync } from "vuex-router-sync";
const unsync = sync(store, router); // done. Returns an unsync callback fnc

Vue.component("moment", momentVue);
Vue.component("zingchart", zingchartVue);

Vue.use(vueNumeralFilterInstaller, { locale: "es-es" });
Vue.use(FlagIcon);
Vue.use(vuescroll);
Vue.use(VueEllipseProgress);
Vue.use(fullscreen);
Vue.use(VueHtml2Canvas);
Vue.use(Tawk, {
  tawkSrc: "https://embed.tawk.to/5ac5c6e4d7591465c709356f/default"
});
Vue.config.productionTip = false;

Vue.filter("format_number", function(value) {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "";
});

Vue.filter("formatDayMonth", function(value) {
  if (!value) return "";
  return Vue.moment(value).format("D MMMM");
});

Vue.filter("formatDate", function(value) {
  if (!value) return "";
  return Vue.moment(value).format("D MMMM YYYY");
});

Vue.filter("formatDateFull", function(value) {
  if (!value) return "";
  return Vue.moment(value).format("dddd, D MMMM YYYY");
});

Vue.filter("formatDatetime", function(value) {
  if (!value) return "";
  return Vue.moment(value).format("D MMMM YYYY, h:mm:ss a");
});

Vue.filter("formatTime", function(value) {
  if (!value) return "";
  return Vue.moment(value).format("h:mm:ss a");
});

Vue.filter("truncate", function(value, length = 50) {
  if (!value) return "";
  if (value.length < length) return value;
  return value.slice(0, length - 1) + " ...";
});

Vue.directive("blur", {
  inserted: function(el) {
    el.onfocus = ev => ev.target.blur();
  }
});

var vm = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (!store.getters.authenticated) {
      // Se necesita login y no está logeado
      store.dispatch("setLoginLayout");
      next({ name: "home" });
    } else {
      // Se necesita login, pero ya estoy logado
      store.dispatch("setAppLayout");
      next(); // go to wherever I'm going
    }
  } else {
    // No requiere autenticación
    next(); // does not require auth, make sure to always call next()!
  }
});

// Global wrapper
window.customFncs = {};
window.customFncs.getTooltip = function(e) {
  return Utils.getTooltip(
    e,
    vm.$store.state,
    vm.$store.getters,
    vm.$store.getters.current_hotel_language
  );
};
