import apiClient from "./Environment";
import mockApiClient from "./MockServerEnvironment";
import * as Utils from "@/utils.js";

export default {
  //Dashboard individual
  getKpisMonthlyGraph(params) {
    return apiClient.get(
      "/get-kpis-monthly-graph" + Utils.buildGetParams(params)
    );
  },
  getSegmentsPeriodGraph(params) {
    return apiClient.get(
      "/get-segments-period-graph" + Utils.buildGetParams(params)
    );
  },
  getKpisPickupMonthGraph(params) {
    return apiClient.get(
      "/get-kpis-pickup-month-graph" + Utils.buildGetParams(params)
    );
  },
  getKpisMonthGraph(params) {
    return apiClient.get(
      "/get-kpis-month-graph" + Utils.buildGetParams(params)
    );
  },
  getSegmentsPickupMonthGraph(params) {
    return apiClient.get(
      "/get-segments-pickup-month-graph" + Utils.buildGetParams(params)
    );
  },
  getKpisPeriod(params) {
    return apiClient.get("/get-kpis-period" + Utils.buildGetParams(params));
  },
  getKpisMonthly(params) {
    return apiClient.get("/get-kpis-monthly" + Utils.buildGetParams(params));
  },

  //Dashboard group
  getHotelsKpisPeriodTable(params) {
    return apiClient.get(
      "/get-hotels-kpis-period-table" + Utils.buildGetParams(params)
    );
  },
  getHotelsKpisPeriodGraph(params) {
    return apiClient.get(
      "/get-hotels-kpis-period-graph" + Utils.buildGetParams(params)
    );
  },

  //Revenue Strategy
  getKpisDaily(params) {
    return apiClient.get("/get-kpis-daily" + Utils.buildGetParams(params));
  },
  getAllKpisDay(params) {
    return mockApiClient.get("/get-kpis-day" + Utils.buildGetParams(params));
  },
  getAllKpisDailyCompset(params) {
    return apiClient.get("/get-compset-daily" + Utils.buildGetParams(params));
  },
  getAllKpisDayCompset(params) {
    return apiClient.get("/get-compset-day" + Utils.buildGetParams(params));
  },
  getAllKpisDailyPickup(params) {
    return apiClient.get("/get-pickup-daily" + Utils.buildGetParams(params));
  },
  getPvpMlosChangesDaily(params) {
    return apiClient.get(
      "/get-pvp-mlos-changes-daily" + Utils.buildGetParams(params)
    );
  },
  getWeatherDaily(params) {
    return apiClient.get("/get-weather-daily" + Utils.buildGetParams(params));
  },
  getKpisDay(params) {
    return apiClient.get(
      "/get-kpis-period-table" + Utils.buildGetParams(params)
    );
  },
  getRoomTypesDay(params) {
    return apiClient.get(
      "/get-roomtypes-day-table" + Utils.buildGetParams(params)
    );
  },
  getRoomTypesEvolutionDay(params) {
    return apiClient.get(
      "/get-roomtypes-day-evolution-table" + Utils.buildGetParams(params)
    );
  },
  setRoomTypesDayRates(data) {
    return apiClient.post("/set-roomtypes-day-rates", data);
  },
  setRoomTypeDayRates(data) {
    return apiClient.post("/set-roomtype-day-rates", data);
  },
  getKpisRangeTable(params) {
    return apiClient.get(
      "/get-kpis-period-table" + Utils.buildGetParams(params)
    );
  },

  // Availability Report
  getRoomTypesDaily(params) {
    return apiClient.get(
      "/get-roomtypes-daily-table" + Utils.buildGetParams(params)
    );
  },

  // Dates Status
  getImportDatesStatus(params) {
    return mockApiClient.get(
      "/get-import-dates-status" + Utils.buildGetParams(params)
    );
  },

  // Year sales
  getYearSalesTable(params) {
    return apiClient.get(
      "/get-year-sales-table" + Utils.buildGetParams(params)
    );
  },
  getMonthlyGraph(params) {
    return apiClient.get(
      "/get-kpis-monthly-graph" + Utils.buildGetParams(params)
    );
  },
  getYearSalesPeriodGraph(params) {
    return apiClient.get(
      "/get-kpis-period-graph" + Utils.buildGetParams(params)
    );
  },
  getYearSalesMonthlyGrownGraph(params) {
    return apiClient.get(
      "/get-kpis-monthly-grown-graph" + Utils.buildGetParams(params)
    );
  },
  getYearSalesPeriodTable(params) {
    return apiClient.get(
      "/get-kpis-period-table" + Utils.buildGetParams(params)
    );
  },

  // Segmentation report
  getSegmentationReportGraph(params) {
    return apiClient.get(
      "/get-dimension-period-graph" + Utils.buildGetParams(params)
    );
  },
  getSegmentationReportTable(params) {
    return apiClient.get(
      "/get-dimension-period-table" + Utils.buildGetParams(params)
    );
  },
  getBookingPace(params) {
    return apiClient.get(
      "/get-booking-pace-graph" + Utils.buildGetParams(params)
    );
  },
  getBookingPaceWeeks(params) {
    return apiClient.get(
      "/get-weekly-booking-pace-graph" + Utils.buildGetParams(params)
    );
  },

  // Revenue
  getRevenueSummaryTable(params) {
    return apiClient.get("/get-kpis-period" + Utils.buildGetParams(params));
  },
  getRevenueChannelsTable(params) {
    return apiClient.get(
      "/get-dimension-period-table" + Utils.buildGetParams(params)
    );
  },
  getRevenueServicetypeData(params) {
    return apiClient.get(
      "/get-dimension-period-table" + Utils.buildGetParams(params)
    );
  },
  getRevenueDaysToReservationGraph(params) {
    return apiClient.get(
      "/get-days-to-reservation-period-graph" + Utils.buildGetParams(params)
    );
  },
  getRevenueDaysToCancellationGraph(params) {
    return apiClient.get(
      "/get-days-to-cancellation-period-graph" + Utils.buildGetParams(params)
    );
  }
};
