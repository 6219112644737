import Vue from "vue";
import RoomTypeService from "@/services/RoomTypeService.js";
import TrialService from "@/services/TrialService";

export const state = {
  // status
  types_mapped_status: 0,
  types_available_status: 0,
  // dataset
  types_mapped: [],
  types_available: []
};

export const mutations = {
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_ROOM_TYPE(state, data) {
    let i = state.types_mapped.findIndex(s => s.id === data.id);
    if (i >= 0) {
      Vue.set(state.types_mapped, i, JSON.parse(JSON.stringify(data)));
    } else {
      state.types_mapped.push(JSON.parse(JSON.stringify(data)));
    }
  },
  DELETE_ROOM_TYPE(state, id) {
    let i = state.types_mapped.findIndex(i => i.id === id);
    if (i >= 0) {
      Vue.delete(state.types_mapped, i);
    }
  },
  SET_ROOM_RATE_PERIODS(state, { id, data }) {
    let i = state.types_mapped.findIndex(s => s.id === id);
    if (i >= 0) {
      Vue.set(state.types_mapped[i], "periods", data);
    }
  },
  HIGHLIGHT_ROOM_TYPE(state, data) {
    let i = state.types_mapped.findIndex(s => s.id === data.id);
    if (i >= 0) {
      for (let j = 0; j < state.types_mapped.length; j++) {
        Vue.set(state.types_mapped[j], "highlighted", 0);
      }
      Vue.set(state.types_mapped[i], "highlighted", 1);
    }
  }
};

export const actions = {
  fetchRoomtypeTypesMapped({ commit, rootGetters }, p) {
    const dataset = "types_mapped";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    RoomTypeService.getRoomTypesMapped({
      hotel_id: rootGetters.current_hotel.id
    }).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRoomtypeTypesAvailable({ commit, rootGetters }, p) {
    const dataset = "types_available";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    RoomTypeService.getRoomTypesAvailable({
      hotel_id: rootGetters.current_hotel.id
    }).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  generateRoomTypesMappingsFromPms({ commit, rootGetters }, data) {
    return RoomTypeService.generateRoomTypesMappingsFromPms({
      hotel_id: rootGetters.current_hotel.id
    });
  },
  generateRoomTypesMappingsFromChannelManager({ commit, rootGetters }, data) {
    return RoomTypeService.generateRoomTypesMappingsFromChannelManager({
      hotel_id: rootGetters.current_hotel.id
    });
  },
  generateRoomTypesMappingsFromBooking({ commit, rootGetters }, data) {
    return RoomTypeService.generateRoomTypesMappingsFromBooking({
      hotel_id: rootGetters.current_hotel.id
    });
  },
  generateRoomTypesMappingsFromWeb({ commit, rootGetters }, data) {
    return RoomTypeService.generateRoomTypesMappingsFromWeb({
      hotel_id: rootGetters.current_hotel.id
    });
  },
  setRoomType({ commit }, room_type) {
    return RoomTypeService.setRoomType(room_type).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_ROOM_TYPE", response);
      return response;
    });
  },
  removeRoomType({ commit }, data) {
    return RoomTypeService.removeRoomType(data).then(response => {
      if (response.error) throw new Error(response.error);
      commit("DELETE_ROOM_TYPE", data.id);
      return response;
    });
  },
  highlightRoomType({ commit }, data) {
    return RoomTypeService.highlightRoomType(data).then(response => {
      if (response.error) throw new Error(response.error);
      commit("HIGHLIGHT_ROOM_TYPE", data);
      return response;
    });
  },
  setRoomTypePosition({ commit }, data) {
    return RoomTypeService.setRoomTypePosition(data).then(response => {
      if (response.error) throw new Error(response.error);
      return response;
    });
  },
  getRoomTypePeriods({ commit }, params) {
    return RoomTypeService.getRoomTypePeriods(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_ROOM_RATE_PERIODS", {
        id: params.room_rate_id,
        data: response
      });
      return response;
    });
  },
  setRoomRatePeriods({ commit }, data) {
    return RoomTypeService.setRoomRatePeriods(data).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_ROOM_RATE_PERIODS", {
        id: data.room_rate_id,
        data: response
      });
      return response;
    });
  }
};

export const getters = {
  pmsRoomTypes: state => {
    return state.types_mapped.map(rt => {
      return {
        code: rt.pms_room_type_code ? rt.pms_room_type_code : rt.code,
        name: rt.name,
        highlighted: rt.highlighted,
        color: rt.color,
        available_rooms: rt.available_rooms
      };
    });
  }
};
