export const state = {
  snackbar: false,
  title: "Title",
  text: "text",
  color: "secondary",
  timeout: 3000,
  multiLine: true
};

export const mutations = {
  SET_SNACKBAR(state, data) {
    state.snackbar = true;
    state.title = data.title;
    state.text = data.text;
    state.color = data.color;
    state.timeout = 3000;
  }
};

export const actions = {
  setSnackbar({ commit }, data) {
    commit("SET_SNACKBAR", data);
  }
};
