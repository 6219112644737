import apiClient from "../services/Environment";

export default {
  getUsers() {
    // return apiClient.get("/users");
    return apiClient.get("/get-app-user");
  },
  getUser(id) {
    return apiClient.get("/users/" + id);
  },
  postUser(user) {
    return apiClient.post("/users", user);
  },
  createUser(user) {
    return apiClient.post("/set-app-user", { user: user });
  },
  deleteUser(id) {
    return apiClient.get("/delete-user?user_id=" + id);
  },
  activateUser(id) {
    return apiClient.get("/activate-user?user_id=" + id);
  },
  deactivateUser(id) {
    return apiClient.get("/deactivate-user?user_id=" + id);
  },
  modifyUser(user) {
    return apiClient.post("/set-app-user", { user: user, no_hotels: true });
  },
  modifyAppUser(user) {
    return apiClient.post("/set-app-user", { user: user, no_hotels: false });
  },
  changePassword(id, password) {
    return apiClient.post("/change-app-user-pwd", {
      user_id: id,
      pwd: password
    });
  },
  setUserSettings(id, settings) {
    return apiClient.post("/set-app-user-settings", {
      user_id: id,
      settings: settings
    });
  }
};
