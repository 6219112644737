<template>
  <v-text-field
    v-model="localValue"
    :label="label"
    :hint="hint"
    :prefix="cleanHttps ? 'https://' : ''"
    :rules="booking_rules"
    type="text"
    @paste="$emit('paste', $event)"
  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="goToBookingHelp">
            mdi-help-circle-outline
          </v-icon>
        </template>
        {{ $t("need_booking_help") }}
      </v-tooltip>
    </template>
  </v-text-field>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "a-booking-url-field",
  computed: {
    ...mapGetters({
      booking_rules: "booking_rules"
    })
  },
  data() {
    return {
      localValue: this.value
    };
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    cleanHttps: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    localValue(newValue) {
      // this.localValue = this.cleanUrl(newValue);
      if (this.cleanHttps) {
        this.$emit("input", this.cleanUrl(newValue));
      } else {
        this.$emit("input", newValue);
      }
    },
    value(newValue) {
      if (this.cleanHttps) {
        this.localValue = this.cleanUrl(newValue);
      } else {
        this.localValue = newValue;
      }
    }
  },
  methods: {
    cleanUrl(url) {
      return url
        ? url
            .replace("http://", "")
            .replace("https://", "")
            .split("?")[0]
        : "";
    },
    goToBookingHelp() {
      const routeUrl = "https://www.veetal.app/booking-url-steps/";
      window.open(routeUrl, "_blank");
    }
  }
};
</script>
