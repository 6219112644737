import apiClient from "./Environment";
import * as Utils from "@/utils.js";

export default {
  getAvailableImports(data) {
    return apiClient.get(
      "/get-metrics-available-imports" + Utils.buildGetParams(data)
    );
  },
  getMetrics(data) {
    return apiClient.get("/get-metrics" + Utils.buildGetParams(data));
  }
};
