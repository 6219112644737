import Vue from "vue";
import ChainService from "@/services/ChainService.js";

export const state = {
  chain: {}
};

export const mutations = {
  SET_CHAIN(state, chain) {
    state.chain = chain;
  }
};

export const actions = {
  fetchChain({ commit }) {
    ChainService.getChain()
      .then(response => {
        commit("SET_CHAIN", response.data);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  updateChainPermissions({ commit }, chain) {
    ChainService.updateChainPermissions(chain)
      .then(response => {
        commit("SET_CHAIN", response.data);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  }
};

export const getters = {};
