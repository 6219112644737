import Vue from "vue";
import Vuex from "vuex";

export const state = {
  layout: "login-layout"
};

export const mutations = {
  SET_LAYOUT(state, payload) {
    state.layout = payload;
  }
};

export const actions = {
  setLoginLayout({ commit }) {
    commit("SET_LAYOUT", "login-layout");
  },
  setAppLayout({ commit }) {
    commit("SET_LAYOUT", "app-layout");
  }
};

export const getters = {
  layout(state) {
    return state.layout;
  }
};
