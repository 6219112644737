import ConnectAPIService from "@/services/ConnectAPIService.js";
import router from "@/router";
import Vue from "vue";

export const state = {
  date: Vue.moment().format("YYYY-MM-DD"),
  rta_request: null,
  rta_kayak_request: null,
  rate_request: null,
  status: null,
  rta_statistics: null,
  rta_kayak_statistics: null,
  rate_statistics: null,
  api_launch_time: null
};

export const mutations = {
  SET_DATE(state, date) {
    state.date = date;
  },
  SET_RTA_STATISTICS(state, statistics) {
    state.rta_statistics = statistics;
  },
  SET_RTA_KAYAK_STATISTICS(state, statistics) {
    state.rta_kayak_statistics = statistics;
  },
  SET_RATE_STATISTICS(state, statistics) {
    state.rate_statistics = statistics;
  },
  SET_RTA_REQUEST(state, request) {
    state.rta_request = request;
  },
  SET_RTA_KAYAK_REQUEST(state, request) {
    state.rta_kayak_request = request;
  },
  SET_RATE_REQUEST(state, request) {
    state.rate_request = request;
  },
  SET_STATUS(state, status) {
    state.status = status;
  },
  SET_CONNECT_API_LAUNCH_TIME(state, api_launch_time) {
    state.api_launch_time = api_launch_time;
  }
};

export const actions = {
  setConnectAPIDate({ commit }, v) {
    commit("SET_DATE", v);
  },
  getRtaStatistics({ commit }, params) {
    return ConnectAPIService.getRtaStatistics(params)
      .then(response => {
        commit("SET_RTA_STATISTICS", response.data.result);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  getRtaKayakStatistics({ commit }, params) {
    return ConnectAPIService.getRtaKayakStatistics(params)
      .then(response => {
        commit("SET_RTA_KAYAK_STATISTICS", response.data.result);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  getRateStatistics({ commit }, params) {
    return ConnectAPIService.getRateStatistics(params)
      .then(response => {
        commit("SET_RATE_STATISTICS", response.data.result);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  getConnectApiStatus({ commit }, params) {
    ConnectAPIService.getStatus(params)
      .then(response => {
        commit(
          "SET_CONNECT_API_LAUNCH_TIME",
          response.data.result.api_launch_time
        );
        commit("SET_STATUS", response.data.result);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  getRtaRequest({ commit }, params) {
    return ConnectAPIService.getRtaRequest(params)
      .then(response => {
        commit("SET_RTA_REQUEST", Object.values(response.data.result));
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  getRtaKayakRequest({ commit }, params) {
    return ConnectAPIService.getRtaKayakRequest(params)
      .then(response => {
        commit("SET_RTA_KAYAK_REQUEST", Object.values(response.data.result));
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  getRateRequest({ commit }, params) {
    return ConnectAPIService.getRateRequest(params)
      .then(response => {
        commit("SET_RATE_REQUEST", Object.values(response.data.result));
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  }
};

export const getters = {};
