import apiClient, { fileClient, fileUploadClient } from "./Environment";

export default {
  async getKpis(data) {
    const response = await apiClient.get("/budget-kpis", {
      params: {
        year: data.year,
        hotel_id: data.hotel_id
      }
    });
    return response.data;
  },
  async getAvailableYears(data) {
    const response = await apiClient.get("/budget-available-years", {
      params: {
        hotel_id: data.hotel_id
      }
    });
    return response.data;
  },
  async getKpiData(data) {
    const response = await apiClient.get("/get-kpi-data", {
      params: {
        year: data.year_before,
        hotel_id: data.hotel_id,
        kpi: data.kpiCode
      }
    });
    return response.data;
  },
  async saveBudget(data) {
    const response = await apiClient.post("/budget-save", data);
    return response.data;
  },
  async getBudgetSavingYears(data) {
    const response = await apiClient.get("/budget-saving-years", {
      params: {
        hotel_id: data.hotel_id
      }
    });
    return response.data;
  },
  async downloadBudgetTemplate(data) {
    const response = await fileClient.post("/get-budget-template", {
      hotel_id: data.hotel_id,
      year: data.year,
      type: data.type
    });
    return response.data;
  },
  async downloadBudget(data) {
    const response = await fileClient.post("/get-budget-to-download", {
      hotel_id: data.hotel_id,
      year: data.year,
      type: data.type
    });
    return response.data;
  },
  async importBudget(data) {
    const response = await fileUploadClient.post("/budget-import", data);
    return response.data;
  }
};
