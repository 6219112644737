import Vue from "vue";
import apiClient, { fileClient } from "./Environment";
import * as Utils from "@/utils.js";

export default {
  getOffersTableData(data) {
    const payload = {
      startDate: Vue.moment().format("YYYY-MM-DD"),
      page: 0,
      columnsPerPage: 14
    };
    Object.assign(payload, data);
    return apiClient.get("/get-offers-table", { params: payload });
  },
  getOffersGroups(params) {
    return apiClient.get("/get-offers-groups" + Utils.buildGetParams(params));
  },
  postOffersChanges(data) {
    return apiClient.post("/set-offers-changes", data);
  },
  async downloadOffers(data) {
    const response = await fileClient.post("/get-offers-csv", {
      hotel_id: data.hotel_id,
      year: data.year
    });
    return response.data;
  }
};
