import BudgetService from "@/services/BudgetService";
import { cleanPunctuation, downloadFile, justNumber } from "@/utils";

export const state = {
  //Used on Budgets's page
  budget_year: null,
  kpis: {},
  new_kpis: {},
  editing_kpis: {},
  editing: false,
  saving_years: [],
  available_years: {},
  kpi_to_edit: "",
  excel_punctuation: "comma",
  copying_previous_year: false,
  show_upload_dialog: false,
  show_upload_daily_dialog: false,
  progress: {},
  id_interval_budget: null,
  milliseconds_interval: 1000 * 5
};

export const getters = {
  getBudgetYear: state => state.budget_year,
  getPreviousBudgetYear: state => state.budget_year - 1,
  getKpis: state => state.kpis,
  getNewKpis: state => state.new_kpis,
  getEditing: state => state.editing,
  isSavingYearBudget: state => state.saving_years.includes(state.budget_year),
  getAvailableYears: state => state.available_years,
  hasPreviousYearBudget: state =>
    state.available_years[state.budget_year - 1] !== undefined,
  hasChanges: state =>
    JSON.stringify(state.kpis) !== JSON.stringify(state.new_kpis),
  hasEditingChanges: state =>
    JSON.stringify(state.new_kpis) !== JSON.stringify(state.editing_kpis),
  hasYearError: state =>
    state.available_years &&
    state.available_years[state.budget_year] &&
    state.available_years[state.budget_year].error &&
    state.available_years[state.budget_year].error !== "",
  getYearErrorText: state =>
    state.available_years &&
    state.available_years[state.budget_year] &&
    state.available_years[state.budget_year].error,
  getYearLastUpdate: state =>
    state.available_years &&
    state.available_years[state.budget_year] &&
    state.available_years[state.budget_year].last_update,
  getYearCacheRefreshed: state =>
    state.available_years &&
    state.available_years[state.budget_year] &&
    state.available_years[state.budget_year].cache_refreshed
};

export const mutations = {
  SET_BUDGET_YEAR: (state, budget_year) => (state.budget_year = budget_year),
  SET_KPIS: (state, kpis) => (state.kpis = kpis),
  SET_NEW_KPIS: (state, new_kpis) => (state.new_kpis = new_kpis),
  SET_EDITING: (state, editing) => (state.editing = editing),
  ADD_SAVING_YEAR: (state, year) => state.saving_years.push(year),
  SET_AVAILABLE_YEARS: (state, years) => (state.available_years = years),
  SET_KPI_TO_EDIT: (state, kpiName) => (state.kpi_to_edit = kpiName),
  SET_EDITING_KPIS: (state, kpis) => (state.editing_kpis = kpis),
  SET_EXCEL_PUNCTUATION: (state, punctuation) =>
    (state.excel_punctuation = punctuation),
  UPDATE_EDITING_KPI: (state, data) => {
    state.editing_kpis[data.kpiCode][data.kpiPeriod] = data.value;
  },
  SET_COPYING_PREVIOUS_YEAR: (state, copying) =>
    (state.copying_previous_year = copying),
  SET_SHOW_UPLOAD_DIALOG: (state, show) => (state.show_upload_dialog = show),
  SET_SHOW_UPLOAD_DAILY_DIALOG: (state, show) =>
    (state.show_upload_daily_dialog = show),
  SET_ID_INTERVAL_BUDGET: (state, callback) => {
    state.id_interval_budget = setInterval(() => {
      if (callback) {
        callback();
      }
    }, state.milliseconds_interval);
  },
  CLEAR_ID_INTERVAL_BUDGET(state) {
    if (state.id_interval_budget) {
      clearInterval(state.id_interval_budget);
      state.id_interval_budget = null;
    }
  },
  SET_PROGRESS(state, progress) {
    state.progress = progress;
  },
  SET_SAVING_YEARS(state, saving_years) {
    state.saving_years = saving_years;
  }
};

export const actions = {
  setBudgetYear({ commit }, year) {
    commit("SET_BUDGET_YEAR", year);
  },
  getBudgetKpis({ commit }, data) {
    commit("SET_KPIS", {});
    commit("SET_NEW_KPIS", {});
    return BudgetService.getKpis(data).then(response => {
      commit("SET_KPIS", response);
      commit("SET_NEW_KPIS", response);
    });
  },
  setEditing({ commit }, editing) {
    commit("SET_EDITING", editing);
  },
  toggleEditing({ commit, state }) {
    commit("SET_EDITING", !state.editing);
  },
  getAvailableYears({ commit }, data) {
    return BudgetService.getAvailableYears(data).then(data => {
      commit("SET_AVAILABLE_YEARS", data.available_years);
    });
  },
  setKpiToEdit({ commit }, data) {
    commit("SET_KPI_TO_EDIT", data);
  },
  setEditingKpis({ commit }, data) {
    commit("SET_EDITING_KPIS", data);
  },
  setNewKpis({ commit }, data) {
    commit("SET_NEW_KPIS", data);
  },
  //   updateEditingKpis({ commit }, data) {
  //     commit("SET_KPI_TO_EDIT", data.kpiName);
  //     commit("SET_EDITING_KPIS", data.kpis);
  //   },
  copyPreviousYearBudget({ commit }, data) {
    //commit("SET_NEW_KPIS", {});
    return BudgetService.getKpis(data).then(response => {
      commit("SET_NEW_KPIS", response);
      commit("SET_EDITING", true);
    });
  },
  discardNewBudget({ commit, state }) {
    commit("SET_NEW_KPIS", state.kpis);
    commit("SET_EDITING", false);
  },
  setExcelPunctuation({ commit }, punctuation) {
    commit("SET_EXCEL_PUNCTUATION", punctuation);
  },
  updateEditingKpi({ commit }, data) {
    commit("UPDATE_EDITING_KPI", data);
  },
  copyFromPreviousYear({ commit, dispatch }, data) {
    commit("SET_COPYING_PREVIOUS_YEAR", true);
    const kpiCode = data.kpiCode;
    return BudgetService.getKpiData(data).then(response => {
      Object.keys(response[kpiCode]).forEach(month => {
        const value = response[kpiCode][month];
        commit("UPDATE_EDITING_KPI", {
          kpiCode,
          kpiPeriod: "periodo" + month,
          value: justNumber(cleanPunctuation(value))
        });
      });
      commit("SET_COPYING_PREVIOUS_YEAR", false);
    });
  },
  showUploadDialog({ commit }, show) {
    commit("SET_SHOW_UPLOAD_DIALOG", show);
  },
  showUploadDailyDialog({ commit }, show) {
    commit("SET_SHOW_UPLOAD_DAILY_DIALOG", show);
  },
  saveNewBudget({ commit, dispatch }, data) {
    commit("ADD_SAVING_YEAR", data.year);
    return BudgetService.saveBudget({
      ...data,
      kpis: state.new_kpis
    }).then(response => {
      dispatch("getAvailableYears", data);
      dispatch("getBudgetKpis", data);
      dispatch("pollSavingYears", { hotel_id: data.hotel_id });
      commit("SET_EDITING", false);
    });
  },
  pollSavingYears({ commit, state, dispatch }, data) {
    BudgetService.getBudgetSavingYears(data).then(response => {
      commit("SET_SAVING_YEARS", Object.keys(response.years));
      commit("SET_PROGRESS", response.years);
      if (Object.keys(response.years).length > 0 && !state.id_interval_budget) {
        commit("SET_ID_INTERVAL_BUDGET", () => {
          dispatch("pollSavingYears", data);
        });
      }
      if (
        Object.keys(response.years).length === 0 &&
        state.id_interval_budget
      ) {
        commit("CLEAR_ID_INTERVAL_BUDGET");
      }
    });
  },
  clearPollSavingYears({ commit }) {
    commit("CLEAR_ID_INTERVAL_BUDGET");
  },
  downloadBudgetTemplate({ commit }, data) {
    BudgetService.downloadBudgetTemplate(data).then(response => {
      downloadFile(
        response,
        "Budget Template " + data.hotel_name + " " + data.type_name + ".xlsx"
      );
    });
  },
  downloadBudget({ commit }, data) {
    BudgetService.downloadBudget(data).then(response => {
      downloadFile(
        response,
        "Budget " + data.hotel_name + " " + data.type_name + ".xlsx"
      );
    });
  },
  importBudget({ commit, dispatch }, data) {
    commit("ADD_SAVING_YEAR", data.year);
    return BudgetService.importBudget(data.formData).then(response => {
      dispatch("getAvailableYears", data);
      dispatch("getBudgetKpis", data);
      commit("SET_EDITING", false);
      dispatch("pollSavingYears", { hotel_id: data.hotel_id });
    });
  }
};
