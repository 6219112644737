import apiClient, { fileClient, fileUploadClient } from "./Environment";

export default {
  async downloadRevenueImportTemplate(data) {
    const response = await fileClient.post("/get-revenue-import-template", {
      hotel_id: data.hotel_id
    });
    return response.data;
  },
  async downloadImportFile(data) {
    const response = await fileClient.post("/get-revenue-import-file", {
      hotel_id: data.hotel_id,
      import_task_id: data.import_task_id
    });
    return response.data;
  },
  async deleteImport(data) {
    const response = await apiClient.post("/delete-revenue-import", {
      hotel_id: data.hotel_id,
      import_task_id: data.import_task_id
    });
    return response.data;
  },
  async downloadImportData(data) {
    const response = await fileClient.post("/get-revenue-import-data", {
      hotel_id: data.hotel_id,
      import_date: data.import_date,
      dimension: data.dimension
    });
    return response.data;
  },
  async importManualRevenue(data) {
    const response = await fileUploadClient.post(
      "/import-manual-revenue",
      data
    );
    return response.data;
  },
  async startAutomaticImport(data) {
    const response = await apiClient.post("/start-automatic-import", data);
    return response.data;
  },
  async importUlysesRevenue(data) {
    const response = await apiClient.post("/import-ulyses-revenue", data);
    return response.data;
  },
  async migrateHotelsdotImports(data) {
    const response = await apiClient.post("/migrate-hotelsdot-imports", data);
    return response.data;
  },
  async updateRevenueDailyColumn(data) {
    const response = await apiClient.post("/update-import-daily-column", data);
    return response.data;
  },
  async updateRevenueReservationColumn(data) {
    const response = await apiClient.post(
      "/update-import-reservation-column",
      data
    );
    return response.data;
  },
  async getRevenueLastImports(data) {
    return apiClient
      .get("/get-revenue-last-imports", {
        params: data
      })
      .then(response => response.data);
  },
  async getImportDatesStatus(data) {
    return apiClient
      .get("/get-import-dates-status", {
        params: data
      })
      .then(response => response.data);
  },
  async getImportDatesCalendar(data) {
    return apiClient
      .get("/get-import-dates-calendar", {
        params: data
      })
      .then(response => response.data);
  },
  async getImportDailyColumns(data) {
    return apiClient
      .get("/get-import-daily-columns", {
        params: data
      })
      .then(response => response.data);
  },
  async getImportReservationColumns(data) {
    return apiClient
      .get("/get-import-reservation-columns", {
        params: data
      })
      .then(response => response.data);
  },
  async getImportDateDetails(data) {
    return apiClient
      .get("/get-import-date-details", {
        params: data
      })
      .then(response => response.data);
  },
  async getHotelsdotAvailableImports(data) {
    return apiClient
      .get("/get-hotelsdot-available-imports", {
        params: data
      })
      .then(response => response.data);
  }
};
