import Vue from "vue";
import VueI18n from "vue-i18n";
var es_messages = require("@/plugins/es.json");
var ca_messages = require("@/plugins/ca.json");
var en_messages = require("@/plugins/en.json");
var fr_messages = require("@/plugins/fr.json");
Vue.use(VueI18n);

const messages = {
  en: en_messages,
  es: es_messages,
  ca: ca_messages,
  fr: fr_messages
};

export default new VueI18n({
  locale: "es", // set locale
  fallbackLocale: "es", // set fallback locale
  messages // set locale messages
});
