import Vue from "vue";
import axios from "axios";
import { environment } from "../../services/Environment";
import i18n from "../../plugins/i18n";
var numeral = require("numeral");

const authClient = axios.create({
  baseURL: environment.auth_url,
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export const state = {
  authenticated: false,
  current_user: {},
  error_login_txt: null,
  profile: {
    "1": "Administrator",
    "2": "Administrator",
    "3": "Administrator",
    "99": "Administrator"
  }
};

export const mutations = {
  SET_AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated;
  },
  UPDATE_CURRENT_USER(state, user) {
    state.current_user = JSON.parse(JSON.stringify(user));
    localStorage.setItem("credentials", JSON.stringify(state.current_user));
  },
  SET_CREDENTIALS(state, { user, language_options }) {
    state.current_user = {
      id: user.id,
      name: user.name,
      surnames: user.surnames,
      username: user.username,
      default_hotel_slug: user.default_hotel_slug,
      type: state.profile[user.profile_id],
      language: user.language,
      active: user.status == "10",
      token: user.token,
      settings: user.settings,
      permissions: user.permissions,
      superuser: user.profile_id === 99
    };

    /* UPDATE LOCALE APP */
    i18n.locale = user.language;
    Vue.moment.locale(user.language);
    const locale = language_options.find(l => l.iso == user.language);
    numeral.locale(locale.numeral_locale);

    localStorage.setItem("credentials", JSON.stringify(state.current_user));
  },
  LOAD_CREDENTIALS(state, { local_user_data, language_options }) {
    state.current_user = JSON.parse(JSON.stringify(local_user_data));
    /* UPDATE LOCALE APP */
    i18n.locale = local_user_data.language;
    Vue.moment.locale(local_user_data.language);
    const locale = language_options.find(
      l => l.iso == local_user_data.language
    );
    numeral.locale(locale.numeral_locale);

    /* Here we must refresh data from users */
  },
  CLEAR_USER_DATA(state, user) {
    state.current_user = {};
    localStorage.clear();
  },
  SET_ERROR(state, error) {
    state.error_login_txt = error;
  },
  SET_USER_SETTINGS(state, settings) {
    state.current_user.settings = settings;
  }
};

export const actions = {
  setAuthenticated({ commit, rootState }, credentials) {
    //No error
    commit("SET_ERROR", null);

    return authClient
      .post("/login", {
        username: credentials.username,
        password: credentials.password
      })
      .then(({ data }) => {
        if (data.result != "error") {
          // Set user data
          var user = data.user;
          const language_options = rootState.language.language_options;
          commit("SET_CREDENTIALS", { user, language_options });

          // Set Authenticated
          commit("SET_AUTHENTICATED", true);
        } else {
          commit("SET_ERROR", data.error);
        }
      });
  },
  rememberPassword({ commit }, username_to_remeber) {
    return authClient.post("/remember-password", {
      username: username_to_remeber
    });
  },
  setUnauthenticated({ commit }) {
    commit("SET_AUTHENTICATED", false);
    commit("CLEAR_USER_DATA");
  },
  setUserType({ commit }, type) {
    commit("SET_USER_TYPE", type);
  },
  canEdit({ commit }, v) {
    commit("CAN_EDIT", v);
  },
  autoLogin({ commit, rootState }, local_user_data) {
    // Set Current user data
    const language_options = rootState.language.language_options;
    commit("LOAD_CREDENTIALS", { local_user_data, language_options }); // restore user data with Vuex
    // Set Authenticated
    commit("SET_AUTHENTICATED", true);
  },
  updateCredentials({ commit, rootState }, user) {
    const language_options = rootState.language.language_options;
    commit("SET_CREDENTIALS", { user, language_options });
  },
  updateCurrentUser({ commit, rootState }, user) {
    commit("UPDATE_CURRENT_USER", user);
  }
};

export const getters = {
  authenticated(state) {
    return state.authenticated;
  },
  error_login_txt(state) {
    return state.error_login_txt;
  }
};
