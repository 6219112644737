import apiClient from "./Environment";

export default {
  getStatus(params) {
    let p = params
      ? "&p=" +
        JSON.stringify({
          environment: params.environment
        })
      : "";
    return apiClient.get("/connect-api?f=test" + p);
  },
  getRtaRequest(params) {
    let p = params
      ? "&p=" +
        JSON.stringify({
          date: params.date,
          customer_name: params.customer_name,
          environment: params.environment
        })
      : "";
    return apiClient.get("/connect-api?f=rta/v1/requests" + p);
  },
  getRateRequest(params) {
    let p = params
      ? "&p=" +
        JSON.stringify({
          date: params.date,
          customer_name: params.customer_name,
          environment: params.environment
        })
      : "";
    return apiClient.get("/connect-api?f=rate/v6/requests" + p);
  },
  getRtaKayakRequest(params) {
    let p = params
      ? "&p=" +
        JSON.stringify({
          date: params.date,
          customer_name: params.customer_name,
          environment: params.environment
        })
      : "";
    return apiClient.get("/connect-api?f=rta/kayak/v1/requests" + p);
  },
  getRtaStatistics(params) {
    let p = params
      ? "&p=" +
        JSON.stringify({
          date: params.date,
          customer_name: params.customer_name,
          environment: params.environment
        })
      : "";
    return apiClient.get("/connect-api?f=rta/v1/stats" + p);
  },
  getRtaKayakStatistics(params) {
    let p = params
      ? "&p=" +
        JSON.stringify({
          date: params.date,
          customer_name: params.customer_name,
          environment: params.environment
        })
      : "";
    return apiClient.get("/connect-api?f=rta/kayak/v1/stats" + p);
  },
  getRateStatistics(params) {
    let p = params
      ? "&p=" +
        JSON.stringify({
          date: params.date,
          customer_name: params.customer_name
        })
      : "";
    return apiClient.get("/connect-api?f=rate/v6/stats" + p);
  }
};
