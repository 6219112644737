import apiClient from "./Environment";

export default {
  getHighlights(data) {
    return apiClient.get(
      "/highlights?hotel_id=" +
        data.hotel_id +
        "&extraction_id=" +
        data.extraction_id
    );
  },
  getHighlightsInfo(data) {
    return apiClient.post("/highlights-info", {
      hotel_id: data.hotel_id
    });
  }
};
