import apiClient from "./Environment";
import * as Utils from "@/utils.js";

export default {
  getSegments(data) {
    return apiClient
      .get("/get-segments" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  getSegmentChannels(data) {
    return apiClient
      .get("/get-channels" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  getChannelIatas() {
    return apiClient.get("/get-channel-iatas").then(response => response.data);
  },
  setSegment(data) {
    return apiClient.post("/set-segment", data).then(response => response.data);
  },
  setChannel(data) {
    return apiClient.post("/set-channel", data).then(response => response.data);
  },
  deleteSegment(data) {
    return apiClient
      .post("/remove-segment", data)
      .then(response => response.data);
  },
  deleteChannel(data) {
    return apiClient
      .post("/remove-channel", data)
      .then(response => response.data);
  },
  getChannelCommissionPeriods(data) {
    return apiClient
      .get("/get-channel-commission-periods" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  setChannelCommissionPeriod(data) {
    return apiClient
      .post("/set-channel-commission-periods", data)
      .then(response => response.data);
  }
};
