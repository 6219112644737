import apiClient from "./Environment";

export default {
  getChain() {
    return apiClient.get("/chain-data");
  },
  updateChainPermissions(chain) {
    return apiClient.post("/update-customer-permissions", {
      permissions: chain.permissions
    });
  }
};
