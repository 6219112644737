import apiClient from "./Environment";
import * as Utils from "@/utils.js";

export default {
  getCalendars(data) {
    return apiClient
      .get("/get-calendars" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  openCalendarYear(data) {
    return apiClient
      .get("/open-calendar-year" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  setCalendar(data) {
    return apiClient
      .post("/set-calendar", data)
      .then(response => response.data);
  },
  deleteCalendar(data) {
    return apiClient
      .post("/remove-calendar", data)
      .then(response => response.data);
  },
  subscribeCalendar(data) {
    return apiClient
      .post("/subscribe-calendar", data)
      .then(response => response.data);
  },
  unsubscribeCalendar(data) {
    return apiClient
      .post("/unsubscribe-calendar", data)
      .then(response => response.data);
  },
  getCalendarEvents(data) {
    return apiClient
      .get("/get-calendar-events" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  getHotelEvents(data) {
    return apiClient
      .get("/get-hotel-events" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  setEvent(data) {
    return apiClient.post("/set-event", data).then(response => response.data);
  },
  deleteEvent(data) {
    return apiClient
      .post("/remove-event", data)
      .then(response => response.data);
  },
  getOpeningPeriod(data) {
    return apiClient
      .get("/get-opening-period" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  setOpeningPeriod(data) {
    return apiClient
      .post("/set-opening-period", data)
      .then(response => response.data);
  },
  getHotelComparativeDates(data) {
    return apiClient
      .get("/get-hotel-dates" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  copyToOtherHotel(data) {
    return apiClient
      .post("/copy-hotel-dates", data)
      .then(response => response.data);
  },
  restoreHotelDates(data) {
    return apiClient
      .post("/restore-hotel-dates", data)
      .then(response => response.data);
  },
  setHotelDate(data) {
    return apiClient
      .post("/set-hotel-date", data)
      .then(response => response.data);
  },
  getHotelComments(data) {
    return apiClient
      .get("/get-comments" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  setComment(data) {
    return apiClient.post("/set-comment", data).then(response => response.data);
  },
  deleteComment(data) {
    return apiClient
      .post("/remove-comment", data)
      .then(response => response.data);
  }
};
