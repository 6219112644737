import Vue from "vue";
import CalendarService from "@/services/CalendarService.js";
import i18n from "@/plugins/i18n";
import * as Utils from "@/utils.js";

export const state = {
  //Used on Calendar's page
  calendar_year: null,
  closing_date: null,
  opening_date: null,
  hotel_events: [],
  hotel_comments: [],
  visible_calendars: [],
  hotel_comparative_dates: {},
  yearly_calendar: {},

  //Used on Event's page
  calendars: [],
  calendar_events: [],

  //Settings
  types: [
    { text: i18n.t("fair"), value: "fair" },
    { text: i18n.t("sports"), value: "sports" },
    { text: i18n.t("convention"), value: "convention" },
    { text: i18n.t("entertainment"), value: "entertainment" },
    { text: i18n.t("local_holiday"), value: "local_holiday" },
    { text: i18n.t("national_holiday"), value: "national_holiday" },
    { text: i18n.t("international_holiday"), value: "international_holiday" }
  ],
  impacts: [
    { text: i18n.t("impact_low"), value: "low", color: "amber" },
    { text: i18n.t("impact_medium"), value: "medium", color: "orange" },
    { text: i18n.t("impact_high"), value: "high", color: "red" },
    {
      text: i18n.t("impact_very_high"),
      value: "very_high",
      color: "red darken-2"
    },
    {
      text: i18n.t("impact_exceptional"),
      value: "exceptional",
      color: "red darken-4"
    }
  ],
  repeats: [
    { text: i18n.t("punctual"), value: "punctual" },
    { text: i18n.t("annual"), value: "annual" },
    { text: i18n.t("biannual"), value: "biannual" }
  ]
};

export const mutations = {
  SET_YEAR(state, calendars) {
    state.calendar_year = calendars;
  },
  SET_CALENDARS(state, calendars) {
    state.calendars = calendars;
  },
  SET_CALENDAR(state, data) {
    let i = state.calendars.findIndex(c => c.id === data.id);
    if (i >= 0) {
      Vue.set(state.calendars, i, JSON.parse(JSON.stringify(data)));
    } else {
      state.calendars.push(JSON.parse(JSON.stringify(data)));
    }
  },
  DELETE_CALENDAR(state, id) {
    const index = state.calendars.findIndex(i => i.id === id);
    if (index >= 0) {
      Vue.delete(state.calendars, index);
    }
  },
  SET_CALENDAR_EVENTS(state, data) {
    state.calendar_events = data;
  },
  SET_OPENING_PERIOD(state, data) {
    state.opening_date = data.opening_date;
    state.closing_date = data.closing_date;
  },
  SET_HOTEL_EVENTS(state, data) {
    state.hotel_events = data;
  },
  DELETE_EVENT(state, id) {
    const index = state.hotel_events.findIndex(event => event.id === id);
    if (index >= 0) {
      Vue.delete(state.hotel_events, index);
    }
    const index2 = state.calendar_events.findIndex(event => event.id === id);
    if (index2 >= 0) {
      Vue.delete(state.calendar_events, index2);
    }
  },
  SET_HOTEL_EVENT(state, data) {
    let i = state.hotel_events.findIndex(event => event.id === data.id);
    if (i >= 0) {
      Vue.set(state.hotel_events, i, JSON.parse(JSON.stringify(data)));
    } else {
      state.hotel_events.push(JSON.parse(JSON.stringify(data)));
    }
  },
  SET_CALENDAR_EVENT(state, data) {
    let i = state.calendar_events.findIndex(event => event.id === data.id);
    if (i >= 0) {
      Vue.set(state.calendar_events, i, JSON.parse(JSON.stringify(data)));
    } else {
      state.calendar_events.push(JSON.parse(JSON.stringify(data)));
    }
  },
  ADD_EVENT(state, data) {
    state.hotel_events.push(JSON.parse(JSON.stringify(data)));
  },
  SET_VISIBLE_CALENDARS(state, data) {
    state.visible_calendars = data;
  },
  SET_HOTEL_COMPARATIVE_DATES(state, data) {
    state.hotel_comparative_dates = data;
  },
  SET_HOTEL_COMPARATIVE_DATE(state, data) {
    state.hotel_comparative_dates[data.date] = JSON.parse(JSON.stringify(data));
  },
  SET_HOTEL_COMMENTS(state, data) {
    state.hotel_comments = data;
  },
  SET_COMMENT(state, data) {
    let i = state.hotel_comments.findIndex(C => C.id === data.id);
    if (i >= 0) {
      Vue.set(state.hotel_comments, i, JSON.parse(JSON.stringify(data)));
    }
  },
  ADD_COMMENT(state, data) {
    state.hotel_comments.push(JSON.parse(JSON.stringify(data)));
  },
  DELETE_COMMENT(state, id) {
    const index = state.hotel_comments.findIndex(event => event.id === id);
    if (index >= 0) {
      Vue.delete(state.hotel_comments, index);
    }
  },
  SET_HOTEL_SUBSCRIPTION(state, data) {
    let calendar = state.calendars.find(c => c.id === data.calendar_id);
    if (calendar !== undefined) {
      let i = calendar.subscribed_hotels.findIndex(h => h === data.hotel_id);
      if (i >= 0) {
        // Unsubscribe
        calendar.subscribed_hotels.splice(i, 1);
      } else {
        //Subscribe
        calendar.subscribed_hotels.push(data.hotel_id);
      }
    }
  }
};

export const actions = {
  setCalendar({ commit }, params) {
    return CalendarService.setCalendar(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_CALENDAR", response);
      return response;
    });
  },
  deleteCalendar({ commit }, params) {
    return CalendarService.deleteCalendar(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("DELETE_CALENDAR", params.id);
      return response;
    });
  },
  setCalendarYear({ commit }, year) {
    commit("SET_YEAR", year);
  },
  getCalendars({ commit }, params) {
    return CalendarService.getCalendars(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_CALENDARS", response);
      return response;
    });
  },
  getHotelEvents({ commit }, params) {
    return CalendarService.getHotelEvents(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_EVENTS", response);
      return response;
    });
  },
  getCalendarEvents({ commit }, params) {
    return CalendarService.getCalendarEvents(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_CALENDAR_EVENTS", response);
      return response;
    });
  },
  getHotelComments({ commit }, params) {
    return CalendarService.getHotelComments(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_COMMENTS", response);
      return response;
    });
  },
  getOpeningPeriod({ commit }, params) {
    return CalendarService.getOpeningPeriod(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_OPENING_PERIOD", response);
      return response;
    });
  },
  setOpeningPeriod({ commit }, params) {
    return CalendarService.setOpeningPeriod(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_OPENING_PERIOD", response);
      return response;
    });
  },
  deleteEvent({ commit }, params) {
    return CalendarService.deleteEvent(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("DELETE_EVENT", params.id);
      return response;
    });
  },
  setHotelEvent({ commit }, params) {
    return CalendarService.setEvent(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_EVENT", response);
      return response;
    });
  },
  setCalendarEvent({ commit }, params) {
    return CalendarService.setEvent(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_CALENDAR_EVENT", response);
      return response;
    });
  },
  setVisibleCalendars({ commit }, data) {
    commit("SET_VISIBLE_CALENDARS", data);
  },
  subscribeHotelCalendar({ commit }, params) {
    return CalendarService.subscribeCalendar(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_SUBSCRIPTION", params);
      return response;
    });
  },
  unsubscribeHotelCalendar({ commit }, params) {
    return CalendarService.unsubscribeCalendar(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_SUBSCRIPTION", params);
      return response;
    });
  },
  getHotelComparativeDates({ commit }, params) {
    return CalendarService.getHotelComparativeDates(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_COMPARATIVE_DATES", response);
      return response;
    });
  },
  copyToOtherHotel({ commit }, params) {
    return CalendarService.copyToOtherHotel(params).then(response => {
      if (response.error) throw new Error(response.error);
      return response;
    });
  },
  restoreHotelDates({ commit }, params) {
    return CalendarService.restoreHotelDates(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_COMPARATIVE_DATES", response);
      return response;
    });
  },
  setHotelDate({ commit }, params) {
    return CalendarService.setHotelDate(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_HOTEL_COMPARATIVE_DATE", response);
      return response;
    });
  },
  setComment({ commit }, params) {
    return CalendarService.setComment(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_COMMENT", response);
      return response;
    });
  },
  addComment({ commit }, params) {
    return CalendarService.setComment(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("ADD_COMMENT", response);
      return response;
    });
  },
  deleteComment({ commit }, params) {
    return CalendarService.deleteComment(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("DELETE_COMMENT", params.id);
      return response;
    });
  }
};

export const getters = {
  // Only id is a parameter, state is by default
  getYearlyCalendar: (state, getters) => {
    let listaDias = Utils.getYearlyCalendar(state.calendar_year, getters);

    return listaDias;
  },
  isOpen: state => date => {
    // If opening_date is before closing date, a date is open if it is between opening and closing dates (both included)
    // But if opening_date is after closing date, a date is open if it is before closing date or after opening date
    let opening_date = Vue.moment(state.opening_date);
    let closing_date = Vue.moment(state.closing_date);
    if (opening_date && closing_date) {
      if (opening_date.isBefore(closing_date)) {
        return date.isBetween(opening_date, closing_date, null, "[]"); // Both included
      } else {
        return date.isBefore(closing_date) || date.isAfter(opening_date);
      }
    } else {
      return false;
    }
  },
  canClose: state => date => {
    return true;
  },
  canOpen: state => date => {
    return true;
  },
  opened_days: (state, getters) => {
    // Iterate months and days from getYearlyCalendar object and filter each day to get opened days
    let opened_days = 0;
    Object.keys(getters.getYearlyCalendar).forEach(month => {
      Object.keys(getters.getYearlyCalendar[month]).forEach(week => {
        getters.getYearlyCalendar[month][week].forEach(day => {
          if (day && day.active) opened_days++;
        });
      });
    });
    return opened_days;
  },
  getHotelCalendars: (state, rootGetters) => {
    return state.calendars.filter(calendar =>
      calendar.subscribed_hotels.includes(rootGetters.current_hotel.id)
    );
  },
  getDayEvents: state => date => {
    let events = [];
    if (
      state.calendar_year !== null &&
      Object.keys(state.hotel_comparative_dates).length > 0 &&
      state.hotel_comparative_dates.hasOwnProperty(date)
    ) {
      events = state.hotel_comparative_dates[date].events;
    }
    return events;
  },
  extendEvents: state => events => {
    // Extend events with calendar name and color
    return events.map(item => {
      let m1 = Vue.moment(item.start_date).format("MMMM");
      let m2 = Vue.moment(item.end_date).format("MMMM");
      item.duration = Utils.getDuration(item.start_date, item.end_date);
      item.month = m1 + (m1 !== m2 ? m2 : "");
      item.days = Utils.getFriendlyPeriod(item.start_date, item.end_date);
      let c = state.calendars.find(
        calendar => calendar.id === item.calendar_id
      );
      item.original_calendar_name = c.name;
      item.editable = c.editable;
      return item;
    });
  },
  getComparativeDates: state => month => {
    let dates = Object.keys(state.hotel_comparative_dates).filter(
      d => Vue.moment(d).month() === month
    );
    let array = [];
    dates.forEach(d => {
      array.push(state.hotel_comparative_dates[d]);
    });
    return array;
  }
};
