import HighlightService from "@/services/HighlightService.js";

/* in this module we will have the data for highlights */

export const state = {
  // status
  info_status: 0,
  list_status: 0,
  // dataset
  info: {},
  list: [],
  // key
  info_key: null,
  list_key: null
};

export const mutations = {
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  }
};

export const actions = {
  fetchHighlightList({ commit, rootState, rootGetters }, p) {
    const dataset = "list";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.highlights_extraction_id
    ) {
      return null;
    }

    const params = {
      hotel_id: rootGetters.current_hotel.id,
      extraction_id: rootState.highlights_extraction_id
    };
    HighlightService.getHighlights(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchHighlightInfo({ commit, rootState, rootGetters }, p) {
    const dataset = "info";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    const params = {
      hotel_id: rootGetters.current_hotel.id
    };
    HighlightService.getHighlightsInfo(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  }
};

export const getters = {
  getHighlightsByType: state => name => {
    return state.list.filter(h => h.literal_type_name == name);
  },
  getHighlightTypes: state => {
    let types = [];
    for (var h of state.list) {
      if (!types.find(type => type.name == h.literal_type_name)) {
        types.push({
          name: h.literal_type_name,
          description: h.literal_type_description,
          icon: h.icon,
          section: h.section,
          extra: h.extra
        });
      }
    }
    return types;
  }
};
