export default {
  // auth_url: "http://marcal.dev.hawgbi.com/auth",
  // api_url: "http://marcal.dev.hawgbi.com/api"
  // auth_url: "http://jordi.marcal.dev.hawgbi.com/auth",
  // api_url: "http://jordi.marcal.dev.hawgbi.com/api"
  // auth_url: "http://rate.dev.hawgbi.com/auth",
  // api_url: "http://rate.dev.hawgbi.com/api"
  // auth_url: "http://rondas.dev.hawgbi.com/auth",
  // api_url: "http://rondas.dev.hawgbi.com/api"
  // auth_url: "http://bls.dev.hawgbi.com/auth",
  // api_url: "http://bls.dev.hawgbi.com/api"
  // auth_url: "http://gna.dev.hawgbi.com/auth",
  // api_url: "http://gna.dev.hawgbi.com/api"
  // auth_url: "http://axel.dev.hawgbi.com/auth",
  // api_url: "http://axel.dev.hawgbi.com/api"
  // auth_url: "http://rcd.dev.hawgbi.com/auth",
  // api_url: "http://rcd.dev.hawgbi.com/api"
  auth_url: "http://clicktotravel.dev.hawgbi.com/auth",
  api_url: "http://clicktotravel.dev.hawgbi.com/api"
  // auth_url: "http://sweethotels.dev.hawgbi.com/auth",
  // api_url: "http://sweethotels.dev.hawgbi.com/api"
  // auth_url: "http://accommodaction.dev.hawgbi.com/auth",
  // api_url: "http://accommodaction.dev.hawgbi.com/api"
};
