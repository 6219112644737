import Vue from "vue";
import SegmentsChannelsService from "@/services/SegmentsChannelsService";

export const state = {
  // status
  segments_status: 0,
  segment_channels_status: 0,
  channel_iatas_status: 0,
  // data
  segments: [],
  segment_channels: [],
  channel_iatas: []
};

export const mutations = {
  SET_DATASET_STATUS_SEGMENTSCHANNELS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET_SEGMENTSCHANNELS(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_SEGMENTS(state, segments) {
    state.segments = segments;
  },
  SET_SEGMENT_CHANNELS(state, channels) {
    state.segment_channels = channels;
  },
  SET_CHANNEL_IATAS(state, iatas) {
    state.channel_iatas = iatas;
  },
  SET_SEGMENT(state, data) {
    let i = state.segments.findIndex(s => s.id === data.id);
    if (i >= 0) {
      Vue.set(state.segments, i, JSON.parse(JSON.stringify(data)));
    } else {
      state.segments.push(JSON.parse(JSON.stringify(data)));
    }
  },
  DELETE_SEGMENT(state, id) {
    const index = state.segments.findIndex(i => i.id === id);
    if (index >= 0) {
      Vue.delete(state.segments, index);
    }
  },
  SET_CHANNEL(state, data) {
    let i = state.segment_channels.findIndex(s => s.id === data.id);
    if (i >= 0) {
      Vue.set(state.segment_channels, i, JSON.parse(JSON.stringify(data)));
    } else {
      state.segment_channels.push(JSON.parse(JSON.stringify(data)));
    }
  },
  DELETE_CHANNEL(state, id) {
    const index = state.segment_channels.findIndex(i => i.id === id);
    if (index >= 0) {
      Vue.delete(state.segment_channels, index);
    }
  },
  SET_CHANNEL_COMMISSION_PERIODS(state, { id, data }) {
    let i = state.segment_channels.findIndex(s => s.id === id);
    if (i >= 0) {
      Vue.set(state.segment_channels[i], "commission_periods", data);
    }
  }
};

export const actions = {
  getSegments({ commit }, params) {
    return SegmentsChannelsService.getSegments({
      hotel_id: params.hotel_id,
      settings: true
    }).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_SEGMENTS", response);
      return response;
    });
  },
  fetchSegmentschannelsSegments({ commit, rootGetters }, p) {
    const dataset = "segments";
    commit("SET_DATASET_STATUS_SEGMENTSCHANNELS", {
      dataset: dataset,
      status: 1
    });
    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id
    };
    return SegmentsChannelsService.getSegments(params).then(response => {
      commit("GET_DATASET_SEGMENTSCHANNELS", {
        dataset: dataset,
        data: response
      });
      commit("SET_DATASET_STATUS_SEGMENTSCHANNELS", {
        dataset: dataset,
        status: 2
      });
    });
  },
  getSegmentChannels({ commit }, params) {
    return SegmentsChannelsService.getSegmentChannels({
      hotel_id: params.hotel_id,
      settings: true
    }).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_SEGMENT_CHANNELS", response);
      return response;
    });
  },
  fetchSegmentschannelsSegmentChannels({ commit, rootGetters }, p) {
    const dataset = "segment_channels";
    commit("SET_DATASET_STATUS_SEGMENTSCHANNELS", {
      dataset: dataset,
      status: 1
    });
    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id
    };
    return SegmentsChannelsService.getSegmentChannels(params).then(response => {
      commit("GET_DATASET_SEGMENTSCHANNELS", {
        dataset: dataset,
        data: response
      });
      commit("SET_DATASET_STATUS_SEGMENTSCHANNELS", {
        dataset: dataset,
        status: 2
      });
    });
  },
  getChannelIatas({ commit }) {
    return SegmentsChannelsService.getChannelIatas().then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_CHANNEL_IATAS", response);
      return response;
    });
  },
  fetchSegmentschannelsChannelIatas({ commit, rootGetters }, p) {
    const dataset = "channel_iatas";
    commit("SET_DATASET_STATUS_SEGMENTSCHANNELS", {
      dataset: dataset,
      status: 1
    });
    return SegmentsChannelsService.getChannelIatas().then(response => {
      commit("GET_DATASET_SEGMENTSCHANNELS", {
        dataset: dataset,
        data: response
      });
      commit("SET_DATASET_STATUS_SEGMENTSCHANNELS", {
        dataset: dataset,
        status: 2
      });
    });
  },
  setSegment({ commit }, segment) {
    return SegmentsChannelsService.setSegment(segment).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_SEGMENT", response);
      return response;
    });
  },
  deleteSegment({ commit }, params) {
    return SegmentsChannelsService.deleteSegment(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("DELETE_SEGMENT", params.id);
      return response;
    });
  },
  setChannel({ commit }, channel) {
    return SegmentsChannelsService.setChannel(channel).then(response => {
      if (response.error) throw new Error(response.error);
      commit("SET_CHANNEL", response);
      return response;
    });
  },
  deleteChannel({ commit }, params) {
    return SegmentsChannelsService.deleteChannel(params).then(response => {
      if (response.error) throw new Error(response.error);
      commit("DELETE_SEGMENT", params.id);
      return response;
    });
  },
  getChannelCommissionPeriods({ commit }, params) {
    return SegmentsChannelsService.getChannelCommissionPeriods(params).then(
      response => {
        if (response.error) throw new Error(response.error);
        commit("SET_CHANNEL_COMMISSION_PERIODS", {
          id: params.channel_id,
          data: response
        });
        return response;
      }
    );
  },
  setChannelCommissionPeriod({ commit }, channel) {
    return SegmentsChannelsService.setChannelCommissionPeriod(channel).then(
      response => {
        if (response.error) throw new Error(response.error);
        commit("SET_CHANNEL_COMMISSION_PERIODS", {
          id: channel.channel_id,
          data: response
        });
        return response;
      }
    );
  }
};

export const getters = {};
