import BiService from "@/services/BiService.js";

/* in this module we will have the data for sections revenue, revenuerestaurant, expense, reputation */

export const state = {
  // status
  report_status: 0,
  daily_report_status: 0,
  year_status: 0, // unused
  month_status: 0, // unused
  week_status: 0, // unused
  day_status: 0, // unused
  reputation_answered_by_time_status: 0,
  reputation_reviews_status: 0,
  // dataset
  report: null,
  daily_report: null,
  year: null, // unused
  month: null, // unused
  week: null, // unused
  day: null, // unused
  reputation_answered_by_time: null,
  reputation_reviews: null,
  // key (unused by now)
  report_key: null,
  daily_report_key: null,
  reputation_answered_by_time_key: null,
  reputation_reviews_key: null
};

export const mutations = {
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  }
};

export const actions = {
  fetchBiReport({ commit, rootState, rootGetters }, p) {
    const dataset = "report";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootGetters.current_hotel.last_import_date.dashboard || // only get data if hotel has been imported
      !rootState.vs_filter_selected_option ||
      !rootState.selected_year ||
      !rootState.otb_option
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      vs: rootState.vs_filter_selected_option,
      hotel_vs: rootState.vs_hotel_selected,
      year: rootState.selected_year,
      month: rootState.selected_month,
      otb_option: rootState.otb_option
    };
    BiService.getReport(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchBiDailyReport({ commit, rootState, rootGetters }, p) {
    const dataset = "daily_report";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.vs_filter_selected_option ||
      !rootState.selected_year ||
      !rootState.otb_option
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      vs: rootState.vs_filter_selected_option,
      hotel_vs: rootState.vs_hotel_selected,
      year: rootState.selected_year,
      month: rootState.selected_month,
      otb_option: rootState.otb_option
    };
    BiService.getDailyReport(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchBiReputationAnsweredByTime({ commit, rootState, rootGetters }, p) {
    const dataset = "reputation_answered_by_time";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.vs_filter_selected_option ||
      !rootState.selected_year
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      vs: rootState.vs_filter_selected_option,
      hotel_vs: rootState.vs_hotel_selected,
      year: rootState.selected_year,
      month: rootState.selected_month
    };
    BiService.getReputationAnsweredByTime(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchBiReputationReviews({ commit, rootState, rootGetters }, p) {
    const dataset = "reputation_reviews";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.selected_year
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      year: rootState.selected_year,
      month: rootState.selected_month
    };
    BiService.getReputationReviews(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  }
};

export const getters = {};
