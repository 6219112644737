var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"disabled":_vm.hide_tooltip,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.show)?[_c('div',{class:_vm.classWrapper},[(_vm.simple)?_c('v-icon',_vm._g({attrs:{"color":_vm.color}},on),[_vm._v(_vm._s(_vm.icon))]):_c('span',_vm._g({staticClass:"increment",class:_vm.classIncrement},on),[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-avatar',{staticClass:"mr-1",attrs:{"color":_vm.avatar_color,"size":_vm.avatar_size}},[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v(_vm._s(_vm.icon))])],1),(
                _vm.difference_value &&
                  _vm.difference_value !== 0 &&
                  _vm.difference_value !== undefined
              )?_c('span',{class:_vm.color + '--text',staticStyle:{"margin-left":"-2px"},domProps:{"innerHTML":_vm._s(_vm.real_absolute ? _vm.difference_text : _vm.percent_text)}}):_vm._e()],1)])],1)]:_vm._e()]}}])},[_c('span',{staticClass:"text-center justify-center align-center",domProps:{"innerHTML":_vm._s(
      '<span style=\'text-align:center;\'>' +
        _vm.$t(_vm.period) +
        ': <b>' +
        _vm.tooltip_text +
        '</b></span>'
    )}})])}
var staticRenderFns = []

export { render, staticRenderFns }