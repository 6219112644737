import ChannelManagerService from "@/services/ChannelManagerService";
import BudgetService from "@/services/BudgetService";
import { downloadFile } from "@/utils";
import Vue from "vue";

export const state = {
  // status
  table_data_status: 0,
  day_data_status: 0,
  filters: {
    rates: [],
    rooms: []
  },
  hoveredRate: undefined,
  channelManagerTableData: {},
  channelManagerGraphData: [],
  loadingTableData: true,
  loadingChannelData: false,
  page: 0,
  maxDays: 14,
  slide_status: 0,
  first_time: true,
  metaClickedRates: [],
  clickedRateId: null,
  windowItems: [],
  expandedRateIds: [],
  changesHistory: [],
  day_data: {},
  bulkPvpMlosStatus: {}
};

export const mutations = {
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_FILTERS: (state, filters) => {
    state.filters = JSON.parse(JSON.stringify(filters));
  },
  SET_HOVERED_RATE: (state, rateId) => {
    state.hoveredRate = rateId;
  },
  SET_CHANNEL_MANAGER_TABLE_DATA: (state, data) => {
    state.channelManagerTableData = { ...data };
  },
  SET_CHANNEL_MANAGER_GRAPH_DATA: (state, data) => {
    state.channelManagerGraphData = [...data];
  },
  SET_PAGE: (state, page) => {
    state.page = page;
  },
  SET_MAX_DAYS: (state, maxDays) => {
    state.maxDays = maxDays;
  },
  SET_SLIDE_STATUS: (state, slide) => {
    state.slide_status = slide;
  },
  SET_FIRST_TIME: (state, first_time) => {
    state.first_time = first_time;
  },
  SET_META_CLICKED_RATES: (state, rates) => {
    state.metaClickedRates = rates;
  },
  ADD_META_CLICKED_RATES: (state, rate) => {
    state.metaClickedRates.push(rate);
  },
  SET_CLICKED_RATE_ID: (state, rate) => {
    state.clickedRateId = rate;
  },
  RESET_CHANNEL_MANAGER_TABLE_DATA_ROW_VALUES: state => {
    state.channelManagerTableData.rows.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        if (cell.price) {
          cell.price = cell.original_price;
        }
        // todo availability
        if (cell.available_of_rooms) {
          cell.available_of_rooms = cell.original_available_of_rooms;
        }
      });
    });
  },
  SET_LOADING_TABLE_DATA: (state, loading) => {
    state.loadingTableData = loading;
  },
  SET_LOADING_CHANNEL_DATA: (state, loading) => {
    state.loadingChannelData = loading;
  },
  PUSH_WINDOW_ITEMS: (state, item) => {
    state.windowItems.push(item);
  },
  POP_WINDOW_ITEMS: state => {
    state.windowItems.pop();
  },
  ADD_EXPANDED_RATE_ID: (state, rateId) => {
    state.expandedRateIds.push(rateId);
  },
  REMOVE_EXPANED_RATE_ID: (state, rateId) => {
    state.expandedRateIds = state.expandedRateIds.filter(e => e !== rateId);
  },
  ADD_CHANGES_HISTORY: (state, payload) => {
    state.changesHistory.push(payload);
  },
  RESET_CHANGES_HISTORY: state => {
    state.changesHistory = [];
  },
  SET_BULK_PVP_MLOS_STATUS: (state, status) => {
    state.bulkPvpMlosStatus = status;
  }
};

export const actions = {
  updateChannelmanagerPage: ({ commit }, value) => {
    commit("SET_PAGE", value);
  },
  updateChannelManagerMaxDays: ({ commit }, value) => {
    commit("SET_MAX_DAYS", value);
  },
  updateChannelmanagerSlideStatus: ({ commit }, value) => {
    commit("SET_SLIDE_STATUS", value);
  },
  updateFilters: ({ commit }, value) => {
    commit("SET_FILTERS", value);
  },
  setHoveredRate: ({ commit }, value) => {
    commit("SET_HOVERED_RATE", value);
  },
  setClickedRateId: ({ commit }, value) => {
    commit("SET_CLICKED_RATE_ID", value);
  },
  resetMetaClickedRate: ({ commit }) => {
    commit("SET_META_CLICKED_RATES", []);
  },
  setMetaClickedRate: ({ commit, state }, value) => {
    if (state.metaClickedRates.length === 2) {
      commit("SET_META_CLICKED_RATES", [value]);
    } else {
      commit("ADD_META_CLICKED_RATES", value);
    }
  },
  resetRowValues: ({ commit }) => {
    commit("RESET_CHANNEL_MANAGER_TABLE_DATA_ROW_VALUES");
    commit("RESET_CHANGES_HISTORY");
  },
  applyRatesPriceChanges: ({ commit, getters, state }, payload) => {
    const rowsToEdit = state.channelManagerTableData.rows.filter(
      // row => row.isRate && payload.form.entity_ids.includes(row.rate_id)
      row => payload.form.entity_ids.includes(row.rate_id)
      // payload.form.entity_ids.includes(row.rate_id) ||
      // (!payload.strict &&
      //   payload.form.entity_ids.includes(row.parent_rate_id))
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          cell.price !== undefined &&
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.price = payload.daysSelected.includes((date.getUTCDay() + 6) % 7)
            ? payload.operation(payload.form.value, cell.price)
            : cell.price * 1;
        }
      });
    });
    // eslint-disable-next-line no-extra-boolean-cast
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY", {
        action: "applyRatesPriceChanges",
        action_type: "price",
        payload
      });
    }
  },
  applyAvailabilityChanges: ({ commit, getters, state }, payload) => {
    const ratesAvailabilityToEdit =
      payload.form.entity_type === "rate"
        ? state.channelManagerTableData.rows.filter(
            row =>
              row.isRateAvailability &&
              payload.form.entity_ids.includes(row.rate_id)
          )
        : [];
    const roomsAvailabilityToEdit =
      payload.form.entity_type === "room"
        ? state.channelManagerTableData.rows.filter(
            row => row.isRoom && payload.form.entity_ids.includes(row.room_id)
          )
        : [];
    const rowsToEdit = [...ratesAvailabilityToEdit, ...roomsAvailabilityToEdit];
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.available_of_rooms = payload.daysSelected.includes(
            (date.getUTCDay() + 6) % 7
          )
            ? payload.form.value
            : cell.original_available_of_rooms * 1;
        }
      });
    });
    // eslint-disable-next-line no-extra-boolean-cast
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY", {
        action: "applyAvailabilityChanges",
        action_type: "availability",
        payload
      });
    }
  },
  applyMinNightsChanges: ({ commit, getters, state }, payload) => {
    const rowsToEdit = state.channelManagerTableData.rows.filter(
      row => payload.form.entity_ids.includes(row.rate_id)
      // row =>
      //   (row.isChannel || row.isRate) &&
      //   payload.form.entity_ids.includes(row.rate_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.minStay = payload.daysSelected.includes(
            (date.getUTCDay() + 6) % 7
          )
            ? payload.operation(payload.form.value, cell.minStay)
            : cell.minStay * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY", {
        action: "applyMinNightsChanges",
        action_type: "min_nights",
        payload
      });
    }
  },
  applyMaxNightsChanges: ({ commit, getters, state }, payload) => {
    const rowsToEdit = state.channelManagerTableData.rows.filter(
      row => payload.form.entity_ids.includes(row.rate_id)
      // row =>
      //   (row.isChannel || row.isRate) &&
      //   payload.form.entity_ids.includes(row.rate_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.maxStay = payload.daysSelected.includes(
            (date.getUTCDay() + 6) % 7
          )
            ? payload.operation(payload.form.value, cell.maxStay)
            : cell.maxStay * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY", {
        action: "applyMaxNightsChanges",
        action_type: "max_nights",
        payload
      });
    }
  },
  fetchChannelmanagerTableData: async ({
    commit,
    state,
    rootGetters,
    dispatch
  }) => {
    let page = state.page;
    let maxDays = state.maxDays;
    const dataset = "table_data";

    if (state.first_time) {
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
      commit("SET_FIRST_TIME", false);
    } else {
      commit("SET_SLIDE_STATUS", 0);
    }

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    try {
      const initialPage = state.page;
      const data = {
        hotel_id: rootGetters.current_hotel.id,
        page: page,
        columnsPerPage: maxDays
      };
      commit("SET_LOADING_TABLE_DATA", true);
      const response = await ChannelManagerService.getChannelManagerTableData(
        data
      );
      commit("SET_CHANNEL_MANAGER_TABLE_DATA", response.data);
      commit("SET_LOADING_TABLE_DATA", false);
      commit("SET_PAGE", page);

      const windowItemsMutation =
        initialPage > page ? "POP_WINDOW_ITEMS" : "PUSH_WINDOW_ITEMS";
      commit(windowItemsMutation, response.data.days);
      if (state.expandedRateIds.length > 0) {
        for (let rate_id of state.expandedRateIds) {
          const data = {
            rate_id,
            dates: response.data.days,
            rowIndex: response.data.rows.findIndex(
              e => e.isRate && e.rate_id === rate_id
            )
          };
          await dispatch("fetchChannelManagerRateChannelsSubtable", data);
        }
      }
      dispatch("applyChangesHistory");

      dispatch("fetchChannelManagerGraphData", {
        start_date: response.data.days[0],
        end_date: response.data.days[response.data.days.length - 1],
        action_type: "price",
        entities: {
          rate_ids: response.data.rows.filter(e => e.isRate).map(e => e.rate_id)
        }
      });

      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
      commit("SET_SLIDE_STATUS", 2);
      return response.data;
    } catch (e) {
      // console.error(e);
    }
  },
  applyChangesHistory: async ({ dispatch, state }) => {
    const currentHistoryLength = state.changesHistory.length;

    if (currentHistoryLength) {
      for (let i = 0; i < currentHistoryLength; i++) {
        const changeHistory = state.changesHistory[i];
        dispatch(changeHistory.action, {
          ...changeHistory.payload,
          doNotaddToHistory: true
        });
      }
    }
  },
  fetchChannelManagerRateChannelsSubtable: async (
    { commit, dispatch, state, rootGetters },
    { rate_id, dates, rowIndex, applyChangesHistory = false }
  ) => {
    try {
      const data = {
        hotel_id: rootGetters.current_hotel.id,
        rate_id,
        dates
      };
      commit("SET_LOADING_CHANNEL_DATA", true);
      const response = await ChannelManagerService.getChannelManagerRateChannelsTableData(
        data
      );
      const tableData = state.channelManagerTableData;
      const currentRows = state.channelManagerTableData.rows;
      tableData.rows = [
        ...currentRows.slice(0, rowIndex + 1),
        ...response.data.rows,
        ...currentRows.slice(rowIndex + 1)
      ];

      commit("SET_CHANNEL_MANAGER_TABLE_DATA", tableData);
      if (applyChangesHistory) {
        commit("RESET_CHANNEL_MANAGER_TABLE_DATA_ROW_VALUES");
        dispatch("applyChangesHistory");
      }
      commit("SET_LOADING_CHANNEL_DATA", false);

      return response.data;
    } catch (e) {
      // console.error(e);
    }
  },
  fetchChannelManagerGraphData: async ({ commit, rootGetters }, payload) => {
    const data = {
      hotel_id: rootGetters.current_hotel.id,
      ...payload
    };
    const response = await ChannelManagerService.getChannelManagerGraphData(
      data
    );

    commit("SET_CHANNEL_MANAGER_GRAPH_DATA", response.data);
    return response.data;
  },
  resetChannelManagerGraphData: async ({ commit }) => {
    commit("SET_CHANNEL_MANAGER_GRAPH_DATA", []);
  },
  reloadChannelManagerGraphData: async (
    { commit, state, dispatch },
    payload
  ) => {
    dispatch("fetchChannelManagerGraphData", {
      start_date: state.channelManagerTableData.days[0],
      end_date:
        state.channelManagerTableData.days[
          state.channelManagerTableData.days.length - 1
        ],
      action_type: "price",
      entities: {
        rate_ids: state.channelManagerTableData.rows
          .filter(e => e.isRate)
          .map(e => e.rate_id)
      }
    });
  },
  removeChannelManagerRateChannelsSubtable: async (
    { commit, state },
    { rate_id }
  ) => {
    try {
      commit("SET_LOADING_CHANNEL_DATA", true);
      const tableData = state.channelManagerTableData;
      const currentRows = state.channelManagerTableData.rows;
      tableData.rows = currentRows.filter(
        row => !row.isChannel || (row.isChannel && row.rate_id !== rate_id)
      );

      commit("SET_CHANNEL_MANAGER_TABLE_DATA", tableData);
      commit("SET_LOADING_CHANNEL_DATA", false);
    } catch (e) {
      // console.error(e);
    }
  },
  addExpandedRateId: ({ commit, state }, rate_id) => {
    commit("ADD_EXPANDED_RATE_ID", rate_id);
  },
  removeExpandedRateId: ({ commit, state }, rate_id) => {
    commit("REMOVE_EXPANED_RATE_ID", rate_id);
  },
  setChanges: async ({ state, rootGetters, commit, dispatch }) => {
    // 1 send state.changesHistory to backend
    const data = {
      hotel_id: rootGetters.current_hotel.id,
      user_id: rootGetters.current_user.id,
      changes: state.changesHistory.map(e => ({
        ...e,
        operation_name: e.payload.operation.name
      }))
    };
    const response = await ChannelManagerService.postChannelManagerChanges(
      data
    );
    // 2 clear state.changesHistory
    commit("RESET_CHANGES_HISTORY");
    // 3 refetch data calling fetchChannelManagerTableData
    dispatch("fetchChannelmanagerTableData");
  },
  updateChangesHistory: ({ commit }, data) => {
    commit("ADD_CHANGES_HISTORY", data);
  },
  fetchChannelmanagerDayData: async ({ commit, rootState, rootGetters }) => {
    const dataset = "day_data";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.rate_date_to_compare
    ) {
      return null;
    }

    try {
      const data = {
        hotel_id: rootGetters.current_hotel.id,
        date: rootState.rate_date_to_compare
      };
      ChannelManagerService.getChannelManagerDayData(data).then(response => {
        commit("GET_DATASET", { dataset: dataset, data: response.data });
        commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
      });
    } catch (e) {
      // console.error(e);
    }
  },
  downloadChannelManagerPricing({ commit }, data) {
    ChannelManagerService.downloadPricing(data).then(response => {
      downloadFile(response, "Pricing.csv");
    });
  },
  bulkUploadPvpMlos({ commit, dispatch }, data) {
    return ChannelManagerService.bulkUploadPvpMlos(data.formData).then(
      response => {
        dispatch("fetchChannelmanagerTableData");
      }
    );
  },
  fetchBulkPvpMlosStatus({ commit, rootGetters }) {
    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    try {
      const data = {
        hotel_id: rootGetters.current_hotel.id
      };
      ChannelManagerService.getBulkPvpMlosStatus(data).then(response => {
        commit("SET_BULK_PVP_MLOS_STATUS", response.data);
      });
    } catch (e) {
      // console.error(e);
    }
  }
};

export const getters = {
  tableHotelLabels: state => {
    return (
      state.channelManagerTableData && state.channelManagerTableData.hotelLabels
    );
  },
  filterRooms: state => {
    return (
      state.channelManagerTableData &&
      state.channelManagerTableData.hotelLabels &&
      state.channelManagerTableData.hotelLabels
        .filter(e => e.isRoom)
        .map(e => ({ ...e, value: e.room_id }))
    );
  },
  getFilterRatesByRoomsIds: state => (roomsIds = []) => {
    if (
      state.channelManagerTableData &&
      state.channelManagerTableData.hotelLabels
    ) {
      return state.channelManagerTableData.hotelLabels
        .filter(e => !e.isHotel)
        .filter(
          e =>
            roomsIds.length === 0 ||
            (roomsIds.length > 0 && roomsIds.includes(e.room_id))
        )
        .filter(e => !e.isRate || (e.isRate && !e.parent_rate_id))
        .map(e => ({ ...e, disabled: e.isRoom, value: e.rate_id }));
    }
    return [];
  },
  filteredRows: state => {
    if (state.channelManagerTableData && state.channelManagerTableData.rows) {
      let filteredRows = [];
      const tableRows = state.channelManagerTableData.rows;
      const { rates, rooms } = state.filters;
      if (rates.length > 0) {
        filteredRows = tableRows.filter(
          row =>
            (row.isRoom &&
              row.rate_ids.some(rate_id => rates.includes(rate_id))) ||
            (row.isRate && rates.includes(row.rate_id)) ||
            (row.isChannel && rates.includes(row.rate_id))
        );
      } else if (rooms.length > 0) {
        filteredRows = tableRows.filter(
          row => rooms.includes(row.room_id) || rooms.includes(row.roomTypeId)
        );
      } else {
        filteredRows = tableRows;
      }
      return filteredRows;
    } else {
      return [];
    }
  },
  getRowsMasterRates: state => {
    return state.channelManagerTableData.rows.filter(
      e => e.isRate && !e.parent_rate_id
    );
  },
  getDayRowsMasterRates: state => {
    return state.day_data.rows.filter(e => e.isRate && !e.parent_rate_id);
  },
  channelManagerChangesPending: state => {
    return state.changesHistory.length > 0;
  }
};
