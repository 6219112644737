import Vue from "vue";
import RateService from "@/services/RateService.js";

export const state = {
  // status
  daily_rates_status: 0,
  daily_availability_status: 0,
  competitors_status: 0,
  day_rates_status: 0,
  available_dates_status: 0,
  available_rate_extractions_status: 0,
  dashboard_rate_resume_period_status: 0,
  // dataset
  daily_rates: [],
  daily_availability: [],
  competitors: [],
  day_rates: [],
  available_dates: [], // Dates with data and its last update date
  available_rate_extractions: [], // All extractions
  dashboard_rate_resume_period_0: {},
  dashboard_rate_resume_period_1: {},
  dashboard_rate_resume_period_2: {},

  // data
  highlight_priority: [],
  date_hover: "",
  day_hover: "",
  show_rate_date_to_compare: false,
  show_rate_highlight: false, // Highlights popup in rate
  compared_competitors: [],
  invisible_competitors: []
};

export const mutations = {
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_RATE_DATE_HOVER(state, payload) {
    state.date_hover = payload;
  },
  SET_RATE_DAY_HOVER(state, payload) {
    state.day_hover = payload;
  },
  SHOW_RATE_DATE_TO_COMPARE(state, payload) {
    state.show_rate_date_to_compare = payload;
  },
  SHOW_RATE_HIGHLIGHT(state, payload) {
    state.show_rate_highlight = payload;
  },
  SET_HIGHLIGHT_PRIORITY(state, payload) {
    state.highlight_priority = payload;
  },
  SET_COMPARED_COMPETITORS(state, payload) {
    state.compared_competitors = payload;
  },
  ADD_INVISIBLE_COMPETITOR(state, payload) {
    state.invisible_competitors.push(payload);
  },
  REMOVE_INVISIBLE_COMPETITOR(state, payload) {
    let index = state.invisible_competitors.indexOf(payload);
    state.invisible_competitors.splice(index, 1);
  },
  EMPTY_INVISIBLE_COMPETITORS(state, payload) {
    state.invisible_competitors = [];
  }
};

export const actions = {
  fetchRateDailyRates({ commit, rootState, rootGetters }, p) {
    const dataset = "daily_rates";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.extraction_id
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      extraction_id: rootState.extraction_id,
      vs_id: rootState.vs_id,
      pax: rootState.pax,
      childs: rootState.childs,
      regime: rootState.regime,
      cancel_policy: rootState.cancel_policy,
      genius: rootState.genius,
      mobile_device: rootState.mobile_device
    };
    RateService.getDailyRates(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRateDailyAvailability({ commit, rootState, rootGetters }, p) {
    const dataset = "daily_availability";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.extraction_id
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      extraction_id: rootState.extraction_id,
      vs_id: rootState.vs_id,
      pax: rootState.pax,
      childs: rootState.childs,
      regime: rootState.regime,
      cancel_policy: rootState.cancel_policy,
      genius: rootState.genius,
      mobile_device: rootState.mobile_device
    };
    RateService.getDailyAvailability(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRateCompetitors({ commit, rootGetters }, p) {
    const dataset = "competitors";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let hotel_id = rootGetters.current_hotel.id;
    RateService.getCompetitors(hotel_id).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRateAvailableDates({ commit, rootState, rootGetters }, p) {
    const dataset = "available_dates";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      extraction_id: rootState.extraction_id
    };
    RateService.getAvailableDates(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRateAvailableRateExtractions({ commit, rootState, rootGetters }, p) {
    const dataset = "available_rate_extractions";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id
    };
    RateService.getAvailableRateExtractions(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRateDayRates({ commit, rootState, rootGetters }, p) {
    const dataset = "day_rates";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.extraction_id ||
      !rootState.rate_date_to_compare
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      extraction_id: rootState.extraction_id,
      vs_id: rootState.vs_id,
      pax: rootState.pax,
      childs: rootState.childs,
      regime: rootState.regime,
      cancel_policy: rootState.cancel_policy,
      genius: rootState.genius,
      mobile_device: rootState.mobile_device,
      rate_date: rootState.rate_date_to_compare
    };
    RateService.getDayRates(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRateDashboardRateResumePeriod({ commit, rootState, rootGetters }, p) {
    const dataset_status = "dashboard_rate_resume_period";
    commit("SET_DATASET_STATUS", { dataset: dataset_status, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    for (let i = 0; i < 3; i++) {
      let month = Vue.moment().add(i, "month");
      let params = {
        hotel_id: rootGetters.current_hotel.id,
        from_date: month.startOf("month").format("YYYY-MM-DD"),
        to_date: month.endOf("month").format("YYYY-MM-DD")
      };
      let dataset = "dashboard_rate_resume_period_" + i;
      RateService.getRateResumePeriod(params).then(response => {
        commit("GET_DATASET", { dataset: dataset, data: response.data });
        commit("SET_DATASET_STATUS", { dataset: dataset_status, status: 2 });
      });
    }
  },
  setRateDateHover({ commit }, v) {
    commit("SET_RATE_DATE_HOVER", v);
  },
  setRateDayHover({ commit }, v) {
    commit("SET_RATE_DAY_HOVER", v);
  },
  showRateDateToCompare({ commit }, v) {
    commit("SHOW_RATE_DATE_TO_COMPARE", v);
  },
  showRateHighlight({ commit }, v) {
    commit("SHOW_RATE_HIGHLIGHT", v);
  },
  setHighlightPriority({ commit }, v) {
    commit("SET_HIGHLIGHT_PRIORITY", v);
  },
  setRateDateToCompare({ commit }, v) {
    commit("SET_RATE_DATE_TO_COMPARE", v);
  },
  setComparedCompetitors({ commit }, v) {
    commit("SET_COMPARED_COMPETITORS", v);
  },
  addVisibleCompetitor({ commit }, v) {
    commit("ADD_INVISIBLE_COMPETITOR", v);
  },
  removeVisibleCompetitor({ commit }, v) {
    commit("REMOVE_INVISIBLE_COMPETITOR", v);
  },
  emptyInvisibleCompetitors({ commit }, v) {
    commit("EMPTY_INVISIBLE_COMPETITORS", v);
  }
};
export const getters = {
  getHotelFromCompetitors: state => {
    return state.competitors.find(competitor => competitor.self == 1);
  },
  getOnlyCompetitors: state => {
    return state.competitors.filter(competitor => !competitor.self);
  },
  getCompetitorById: state => id => {
    return state.competitors.find(competitor => competitor.id == id);
  }
};
