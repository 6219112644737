import apiClient from "./Environment";
import * as Utils from "@/utils.js";

export default {
  getDailyRates(data) {
    return apiClient.post("/get-daily-min-rates", data);
  },
  getCompetitors(id) {
    return apiClient.get("/hotel-competitors?hotel_id=" + id);
  },
  getHighlights(data) {
    return apiClient.get(
      "/highlights?hotel_id=" +
        data.hotel_id +
        "&extraction_id=" +
        data.extraction_id
    );
  },
  getDayRates(data) {
    return apiClient.post("/get-rates", data);
  },
  getDailyAvailability(data) {
    return apiClient.post("/get-daily-rate-availability", data);
  },
  getHighlightsInfo(data) {
    return apiClient.post("/highlights-info", {
      hotel_id: data.hotel_id,
      extraction_id: data.extraction_id
    });
  },
  getAvailableDates(data) {
    data.section = "rate";
    return apiClient.post("/get-available-dates-by-section", data);
  },
  getAvailableRateExtractions(data) {
    return apiClient.post("/get-available-rate-extractions", data);
  },
  getRateResumePeriod(data) {
    return apiClient.get(
      "/get-rate-resume-period-graph" + Utils.buildGetParams(data)
    );
  }
};
