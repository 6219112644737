import apiClient from "./Environment";

export default {
  getHotels() {
    return apiClient.get("/get-hotels-resume");
  },
  getAvailableMonths(hotel_id, section, vs, hotel_vs) {
    return apiClient.post("/get-available-months-by-section", {
      hotel_id: hotel_id,
      section: section,
      vs: vs,
      hotel_vs: hotel_vs
    });
  },
  getAllHotels() {
    return apiClient.get("/get-app-hotels");
  },
  modifyHotel(hotel) {
    return apiClient.post("/set-app-hotel", { hotel: hotel });
  },
  activateHotel(hotel_id) {
    return apiClient.post("/activate-hotel", { hotel_id });
  },
  deactivateHotel(hotel_id) {
    return apiClient.post("/deactivate-hotel", { hotel_id });
  },
  getCurrencyConversionPeriodsHotel(hotel_id) {
    return apiClient.post("/get-currency-conversions-hotel", { hotel_id });
    // return Promise.resolve({
    //   data: [
    //     {
    //       start_date: "2024-01-01",
    //       end_date: "2024-05-31",
    //       currency_conversion: 1.2
    //     },
    //     {
    //       start_date: "2024-07-01",
    //       end_date: "2024-12-31",
    //       currency_conversion: 1.1
    //     }
    //   ]
    // });
  },
  setCurrencyConversionPeriodsHotel(data) {
    return apiClient.post("/set-currency-conversions-hotel", data);
  }
};
