import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      meta: {
        title: "Login",
        menu: "login"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/Login.vue")
    },
    {
      path: "/",
      name: "home",
      meta: {
        title: "Home",
        menu: "home"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/Login.vue")
    },
    {
      path: "/:hotel_slug/dashboard",
      name: "dashboard",
      props: true,
      meta: {
        title: "dashboard",
        menu: "dashboard",
        section: "reputation"
      },
      component: () => import(/* webpackChunkName: "test" */ "./views/Home.vue")
    },
    {
      path: "/:hotel_slug/channel-manager",
      name: "channel_manager",
      meta: {
        title: "channel_manager",
        subtitle: "",
        menu: "channel_manager",
        section: "channel_manager"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/ChannelManager.vue")
    },
    {
      path: "/:hotel_slug/revenue/rate",
      name: "rate",
      meta: {
        title: "rate",
        subtitle: "rate_subtitle",
        menu: "rate",
        section: "rate"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/revenue/Rate.vue")
    },
    {
      path: "/:hotel_slug/rms-strategy-hotelsdot",
      name: "rms_strategy_hotelsdot",
      meta: {
        title: "revenue_strategy",
        subtitle: "",
        menu: "rms_strategy_hotelsdot",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/hotelsdot/rmsStrategy.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-strategy-basic",
      name: "rms_strategy_basic",
      meta: {
        title: "revenue-strategy",
        subtitle: "",
        menu: "rms_strategy_basic",
        section: "revenue"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/rms/rmsStrategy.vue")
    },
    {
      path: "/:hotel_slug/rms-strategy",
      name: "rms_strategy",
      meta: {
        title: "revenue-strategy",
        subtitle: "",
        menu: "rms_strategy",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/default/rmsStrategy.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-strategy-axel",
      name: "rms_strategy_axel",
      meta: {
        title: "revenue-strategy",
        subtitle: "",
        menu: "rms_strategy_axel",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/axel/rmsStrategy.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-strategy-grid",
      name: "rms_strategy_grid",
      meta: {
        title: "revenue-strategy",
        subtitle: "",
        menu: "rms_strategy_grid",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/axel/rmsStrategy.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-year-sales-axel",
      name: "rms_year_sales_axel",
      meta: {
        title: "year-sales-report",
        menu: "rms_year_sales_axel",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/axel/rmsYearSales.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-year-sales",
      name: "rms_year_sales",
      meta: {
        title: "year-sales-report",
        menu: "rms_year_sales",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/hotelsdot/rmsYearSales.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-year-sales-report",
      name: "rms_year_sales_report",
      meta: {
        title: "year-sales-report",
        menu: "rms_year_sales_report",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/default/rmsYearSales.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-year-sales-grid",
      name: "rms_year_sales_grid",
      meta: {
        title: "year-sales-report",
        menu: "rms_year_sales_grid",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/axel/rmsYearSales.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-availability-hotelsdot",
      name: "rms_availability_hotelsdot",
      meta: {
        title: "availability-report",
        subtitle: "",
        menu: "rms_availability_hotelsdot",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/hotelsdot/rmsAvailability.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-availability",
      name: "rms_availability",
      meta: {
        title: "availability-report",
        subtitle: "",
        menu: "rms_availability",
        section: "revenue"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/rms/rmsAvailability.vue")
    },
    {
      path: "/:hotel_slug/rms-pricing",
      name: "rms_pricing",
      meta: {
        title: "pricing-report",
        subtitle: "",
        menu: "rms_pricing",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/hotelsdot/rmsPricing.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-strategy-clicktotravel",
      name: "rms_strategy_clicktotravel",
      meta: {
        title: "revenue-strategy",
        subtitle: "",
        menu: "rms_strategy_clicktotravel",
        section: "revenue"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/rms/clicktotravel/rmsStrategy.vue"
        )
    },
    {
      path: "/:hotel_slug/rms-special-rates",
      name: "rms_special_rates",
      meta: {
        title: "special-rates",
        subtitle: "",
        menu: "rms_special_rates",
        section: "revenue"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/SpecialRates.vue")
    },
    {
      path: "/:hotel_slug/rms-revenue",
      name: "rms_revenue",
      meta: {
        title: "revenue-report",
        subtitle: "",
        menu: "rms_revenue",
        section: "revenue"
      },
      component: () => import("./views/rms/rmsRevenue.vue")
    },
    {
      path: "/:hotel_slug/rms-segment-report",
      name: "rms_segment_report",
      meta: {
        title: "segments-report",
        menu: "rms_segment_report",
        section: "revenue"
      },
      component: () => import("./views/rms/hotelsdot/rmsSegmentReport.vue")
    },
    {
      path: "/:hotel_slug/rms-channel-report",
      name: "rms_channel_report",
      meta: {
        title: "channels-report",
        menu: "rms_channel_report",
        section: "revenue"
      },
      component: () => import("./views/rms/hotelsdot/rmsChannelReport.vue")
    },
    {
      path: "/:hotel_slug/rms-room-types-report",
      name: "rms_room_types_report",
      meta: {
        title: "room-types-report",
        menu: "rms_room_types_report",
        section: "revenue"
      },
      component: () => import("./views/rms/hotelsdot/rmsRoomTypesReport.vue")
    },
    {
      path: "/:hotel_slug/revenue/parity",
      name: "parity",
      meta: {
        title: "parity",
        subtitle: "parity_subtitle",
        menu: "parity",
        section: "parity"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/revenue/Parity.vue")
    },
    {
      path: "/:hotel_slug/reputation",
      name: "reputation",
      meta: {
        title: "reputation",
        subtitle: "",
        menu: "reputation",
        section: "reputation"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/reputation/Reputation.vue"
        )
    },
    {
      path: "/:hotel_slug/reputation/:ota",
      props: true,
      name: "ota",
      meta: {
        title: "ota",
        menu: "ota",
        section: "reputation"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/reputation/Ota.vue")
    },
    {
      path: "/:hotel_slug/reputationsurvey",
      name: "survey",
      meta: {
        title: "survey",
        menu: "survey",
        section: "reputation"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/reputation/Survey.vue")
    },
    {
      path: "/:hotel_slug/highlights",
      name: "highlights",
      meta: {
        title: "assistant",
        subtitle: "assistant_page_subtitle",
        menu: "highlights"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/Highlights.vue")
    },
    {
      path: "/:hotel_slug/version",
      name: "version",
      meta: {
        title: "version",
        subtitle: "",
        menu: "version"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/Version.vue")
    },
    {
      path: "/:hotel_slug/settings",
      name: "settings",
      meta: {
        title: "settings",
        menu: "settings"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Settings.vue")
    },
    {
      path: "/:hotel_slug/settings/team",
      name: "team",
      meta: {
        title: "team",
        subtitle: "",
        menu: "team"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Team.vue")
    },
    {
      path: "/:hotel_slug/settings/hotels",
      name: "hotelmanagement",
      meta: {
        title: "hotel-management",
        subtitle: "",
        menu: "hotelmanagement"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Hotel.vue")
    },
    {
      path: "/:hotel_slug/settings/customer",
      name: "customer",
      meta: {
        title: "customer-contracted-sections",
        subtitle: "",
        menu: "customer"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Customer.vue")
    },
    {
      path: "/:hotel_slug/settings/api-status",
      name: "api-status",
      meta: {
        title: "api_status",
        subtitle: "",
        menu: "api-status"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/ApiStatus.vue")
    },
    {
      path: "/:hotel_slug/settings/trial",
      name: "trial",
      meta: {
        title: "trial",
        subtitle: "",
        menu: "trial"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Trial.vue")
    },
    {
      path: "/:hotel_slug/settings/requests",
      name: "requests",
      meta: {
        title: "web_requests",
        subtitle: "",
        menu: "requests"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Requests.vue")
    },
    {
      path: "/:hotel_slug/settings/room-types",
      name: "room-types",
      meta: {
        title: "room_types",
        subtitle: "",
        menu: "room-types"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/RoomTypes.vue")
    },
    {
      path: "/:hotel_slug/settings/budget",
      name: "budget",
      meta: {
        title: "budget",
        subtitle: "",
        menu: "budget",
        section: ["revenue", "expense", "reputation"]
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Budget.vue")
    },
    {
      path: "/:hotel_slug/settings/accounting",
      name: "accounting",
      meta: {
        title: "accounting",
        subtitle: "",
        menu: "accounting"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Accounting.vue")
    },
    {
      path: "/:hotel_slug/settings/calendar",
      name: "calendar",
      meta: {
        title: "calendar",
        subtitle: "",
        menu: "calendar",
        section: ["rate", "revenue", "reputation"]
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Calendar.vue")
    },
    {
      path: "/:hotel_slug/settings/event-management",
      name: "event-management",
      meta: {
        title: "event-management",
        subtitle: "",
        menu: "event-management",
        section: ["rate", "revenue", "reputation"]
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/settings/EventManagement.vue"
        )
    },
    {
      path: "/:hotel_slug/settings/metrics",
      name: "metrics",
      meta: {
        title: "metrics",
        subtitle: "",
        menu: "metrics",
        section: "settings"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/settings/Metrics.vue")
    },
    {
      path: "/:hotel_slug/settings/segments-channels",
      name: "segments-channels",
      meta: {
        title: "segments-channels",
        subtitle: "",
        menu: "segments-channels",
        section: "rate"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/settings/SegmentsChannels.vue"
        )
    },
    {
      path: "/:hotel_slug/a-components",
      name: "a-components",
      meta: {
        title: "example_page",
        menu: "hotel",
        section: "reputation"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/examples/AComponents.vue"
        )
    },
    {
      path: "/:hotel_slug/settings/revenue-import",
      name: "revenue-import",
      meta: {
        title: "revenue_import",
        subtitle: "",
        menu: "revenue-import"
      },
      component: () =>
        import(
          /* webpackChunkName: "test" */ "./views/settings/RevenueImport.vue"
        )
    },
    /* {
          path: "/:hotel_slug/a-box",
          name: "a-box",
          meta: {
            title: "example_page",
            menu: "hotel",
            section: "reputation"
          },
          component: () =>
            import("./views/examples/ABox.vue")
        }, */
    {
      path: "/:hotel_slug/test",
      name: "test",
      meta: {
        title: "test",
        menu: "version",
        section: "reputation"
      },
      component: () => import(/* webpackChunkName: "test" */ "./views/Test.vue")
    },
    {
      path: "/:hotel_slug/test-columns",
      name: "test_columns",
      meta: {
        title: "test",
        menu: "version",
        section: "reputation"
      },
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/TestColumns.vue")
    },
    // NOT FOUND
    {
      path: "*",
      name: "not-found",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/NotFound.vue")
    }
  ]
});
