import Vue from "vue";
import apiClient, { fileClient, fileUploadClient } from "./Environment";

export default {
  getChannelManagerTableData(data) {
    const payload = {
      startDate: Vue.moment().format("YYYY-MM-DD"),
      page: 0,
      columnsPerPage: 14
    };
    Object.assign(payload, data);
    return apiClient.get("/channel-manager-table-data", { params: payload });
  },
  getChannelManagerRateChannelsTableData(data) {
    const payload = {
      dates: [
        Vue.moment().format("YYYY-MM-DD"),
        Vue.moment()
          .add(1, "days")
          .format("YYYY-MM-DD")
      ],
      rate_id: undefined
    };
    Object.assign(payload, data);
    return apiClient.post("/channel-manager-rate-channels-table-data", payload);
  },
  getChannelManagerDayData(data) {
    const payload = {
      date: Vue.moment().format("YYYY-MM-DD")
    };
    Object.assign(payload, data);
    return apiClient.get("/channel-manager-day-data", { params: payload });
  },
  postChannelManagerChanges(data) {
    return apiClient.post("/channel-manager-rate-changes-save", data);
  },
  getChannelManagerGraphData(data) {
    return apiClient.post("/channel-manager-graph-data", data);
  },
  async downloadPricing(data) {
    const response = await fileClient.post("/get-channel-manager-pricing-csv", {
      hotel_id: data.hotel_id,
      year: data.year
    });
    return response.data;
  },
  async bulkUploadPvpMlos(data) {
    const response = await fileUploadClient.post("/bulk-upload-pvp-mlos", data);
    return response.data;
  },
  getBulkPvpMlosStatus(data) {
    return apiClient.get("/bulk-pvp-mlos-status", { params: data });
  }
};
