<template>
  <div :class="wrapper_class">
    <div class="main-kpi">
      <a-kpi
        :kpi="kpi"
        :value="value"
        :locale="locale"
        :clas="clas"
        :large="large"
        :x_large="x_large"
        :small="small"
        :x_small="x_small"
        :xx_small="xx_small"
        right
      ></a-kpi>
    </div>
    <div :class="increments_class">
      <div class="d-flex">
        <div
          class="increment-column kpi-wrapper-2"
          :class="size_class"
          v-for="(vs_value, index) in vs_values"
          :key="index"
        >
          <a-increment
            :value="value"
            :vs_value="vs_value"
            :period="getPeriod(index)"
            :kpi="kpi"
            :simple="simple"
            :absolute="absolute"
          >
          </a-increment>
        </div>
        <div
          class="increment-column kpi-wrapper-2"
          :class="size_class"
          v-if="has_right_slot"
        >
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aIncrement from "@/components/rms_ui/aIncrement";
import aKpi from "@/components/rms_ui/aKpi";

export default {
  name: "a-kpi-columns",
  components: {
    aIncrement,
    aKpi
  },
  props: {
    kpi: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      nullable: true,
      required: false
    },
    vs_values: {
      type: Array,
      required: false
    },
    vs_periods: {
      type: Array,
      required: false
    },
    locale: {
      type: Object,
      required: false
    },
    absolute: {
      type: Boolean,
      required: false
    },
    clas: {
      type: String,
      required: false
    },
    simple: {
      type: Boolean,
      required: false
    },
    hide_kpi: {
      type: Boolean,
      required: false
    },
    vertical: {
      type: Boolean,
      required: false
    },
    left: {
      type: Boolean,
      required: false
    },
    right: {
      type: Boolean,
      required: false
    },
    large: {
      type: Boolean,
      required: false
    },
    x_large: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      required: false
    },
    x_small: {
      type: Boolean,
      required: false
    },
    xx_small: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    getPeriod(index) {
      return this.vs_periods && this.vs_periods.length === this.vs_values.length
        ? this.vs_periods[index]
        : null;
    }
  },
  computed: {
    size_class() {
      return this.x_large
        ? "x-large"
        : this.large
        ? "large"
        : this.small
        ? "small"
        : this.x_small
        ? "x-small"
        : this.xx_small
        ? "xx-small"
        : "";
    },
    columns() {
      return this.vs_periods ? 1 + this.vs_periods.length : 1;
    },
    wrapper_class() {
      let classes = [
        "kpi-wrapper-columns",
        "kpi-wrapper-columns-" + this.columns
      ];
      if (this.vertical) {
        classes.push("kpi-wrapper-columns-vertical");
      }
      return classes.join(" ");
      //return "kpi-wrapper-columns kpi-wrapper-columns-" + this.columns;
    },
    increments_class() {
      return (
        "increments-wrapper increments-wrapper-columns-" + (this.columns - 1)
      );
    },
    has_right_slot() {
      return this.$slots.right !== undefined;
    }
  }
};
</script>

<style lang="scss">
.kpi-wrapper-columns {
  width: 100%;
  display: flex;
  align-items: center;
  .main-kpi {
    flex: 0 0 50%;
  }
  .increments-wrapper {
    width: 100%;
    align-items: center;
    flex: 0 0 50%;
    .increment-column {
      flex: 1;
      justify-content: flex-start;
    }
  }
  &.kpi-wrapper-columns-3 {
    .main-kpi {
      flex: 0 0 33.33%;
    }
    .increments-wrapper {
      flex: 0 0 66.66%;
    }
  }
  &.kpi-wrapper-columns-4 {
    .main-kpi {
      flex: 0 0 25%;
    }
    .increments-wrapper {
      flex: 0 0 75%;
    }
  }
  &.kpi-wrapper-columns-vertical {
    flex-direction: column;
    gap: 5px;
    .increments-wrapper {
      > .d-flex {
        justify-content: center;
        gap: 5px;
        .increment-column {
          justify-content: center;
          flex: 0;
        }
      }
    }
  }
}
</style>
