import apiClient from "./Environment";

export default {
  getRequests() {
    return apiClient.get("/get-trial-requests");
  },
  changeExternalId(id, external_id) {
    return apiClient.post("/change-trial-external-id", {
      id: id,
      external_id: external_id
    });
  },
  changeComments(id, comments) {
    return apiClient.post("/change-trial-comments", {
      id: id,
      comments: comments
    });
  },
  setTrialContactDone(id) {
    return apiClient.post("/change-trial-contact-done", {
      id: id
    });
  },
  setTrialContactPending(id) {
    return apiClient.post("/change-trial-contact-pending", {
      id: id
    });
  },
  setTrialEnabled(id) {
    return apiClient.post("/change-trial-enable", {
      id: id
    });
  },
  setTrialDisabled(id) {
    return apiClient.post("/change-trial-disable", {
      id: id
    });
  },
  deleteTrial(id) {
    return apiClient.post("/delete-trial", {
      id: id
    });
  },
  changeConfiguration(id, hotel_booking_url, competitor_booking_urls) {
    return apiClient.post("/change-trial-configuration", {
      id: id,
      hotel_booking_url: hotel_booking_url,
      competitor_booking_urls: competitor_booking_urls
    });
  },
  addTrial(fullname, email, hotel_url) {
    return apiClient.post("/add-trial", {
      fullname: fullname,
      email: email,
      hotel_url: hotel_url
    });
  }
};
