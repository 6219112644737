import i18n from "@/plugins/i18n";

export const getters = {
  email_rules: () => {
    return [
      v => !!v || i18n.t("cant_be_empty"),
      v => /.+@.+/.test(v) || i18n.t("valid_email")
    ];
  },
  password_rules: () => {
    return [
      v => !!v || i18n.t("cant_be_empty"),
      v => (v && v.length >= 8) || i18n.t("at_least_8_character")
    ];
  },
  date_rules: () => {
    return [v => !!v || i18n.t("cant_be_empty")];
  },
  name_rules: () => {
    return [v => !!v || i18n.t("cant_be_empty")];
  },
  booking_rules: () => {
    return [
      v => !!v || i18n.t("Booking URL is required"),
      v =>
        /www\.booking\.com\/hotel\/\w\w\/([^.]*)\.\w\w-?\w?\w?.html/.test(v) ||
        i18n.t("Booking URL must be valid")
    ];
  }
};
