import axios from "axios";
import store from "@/store/store.js";
import router from "@/router.js";
import params from "@/params.js";

export const environment = {
  auth_url: params.auth_url,
  api_url: params.api_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("credentials")
      ? "Bearer " + JSON.parse(localStorage.getItem("credentials")).token
      : ""
  }
};

//Basic Clients settings
const apiClient = axios.create({
  baseURL: environment.api_url,
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// Token Authorization management
apiClient.interceptors.request.use(
  config => {
    if (localStorage.getItem("credentials")) {
      config.headers["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("credentials")).token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Security if Unauthorized request is done
apiClient.interceptors.response.use(
  response => response, // simply return the response
  error => {
    if (error.response && error.response.status === 401) {
      // if we catch a 401 error
      store.dispatch("setLoginLayout");
      store.dispatch("setUnauthenticated").then(() => {
        router.push({
          name: "login"
        });
      }); // force a log out
    }
    return Promise.reject(error); // reject the Promise, with the error as the reason
  }
);

// Client for file download
const fileClient = axios.create({
  baseURL: environment.api_url,
  withCredentials: false, // This is the default
  responseType: "blob",
  headers: {
    Authorization: localStorage.getItem("credentials")
      ? "Bearer " + JSON.parse(localStorage.getItem("credentials")).token
      : ""
  }
});

// Clients for file upload
const fileUploadClient = axios.create({
  baseURL: environment.api_url,
  withCredentials: false, // This is the default
  headers: {
    Authorization: localStorage.getItem("credentials")
      ? "Bearer " + JSON.parse(localStorage.getItem("credentials")).token
      : "",
    "Content-Type": "multipart/form-data"
  }
});

export default apiClient;
export { fileClient, fileUploadClient };
