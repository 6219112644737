import apiClient from "./Environment";

export default {
  getWebs(hotel_id) {
    return apiClient.get("/hotel-webs?hotel_id=" + hotel_id);
  },
  getBookingBasic(hotel_id) {
    return apiClient.get("/hotel-booking-basic?hotel_id=" + hotel_id);
  },
  getChannels(hotel_id) {
    return apiClient.get("/hotel-parity-channels?hotel_id=" + hotel_id);
  },
  getAvailableDates(data) {
    data.section = "paritychannel";
    return apiClient.post("/get-available-dates-by-section", data);
  },
  getParityChannelLinks(data) {
    return apiClient.post("/get-paritychannel-links", data);
  }
};
