import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import es from "vuetify/es5/locale/es";
import en from "vuetify/es5/locale/en";
import fr from "vuetify/es5/locale/fr";
import ca from "vuetify/es5/locale/ca";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es, en, fr, ca },
    current: "es"
  },
  theme: {
    themes: {
      // veetal official colors
      // #21b5db
      // #13b0d9
      // #1f81ca
      // #1576c7
      // #2148bd
      // mes lila
      // #2148bd
      // #2e21bd
      light: {
        // primary: colors.lime.darken3,
        primary: "#2e21bd",
        secondary: "#1576c7",
        accent: "#21b5db",
        green: "#1B998B",
        orange: "#FAA916",
        red: "#e64d3c"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
});
