import Vue from "vue";
import UserService from "@/services/UserService.js";

export const state = {
  users: [],
  user: { id: "" },
  show_change_password: false,
  user_to_delete: null,
  user_to_changepassword: null,
  new_member_model: {},
  available_permissions: {}
};

export const mutations = {
  GET_USERS(state, users) {
    if (users) {
      state.users = Object.values(users);
    }
  },
  SET_NEW_USER_MODEL(state, new_member_model) {
    if (new_member_model) {
      state.new_member_model = new_member_model;
    }
  },
  SET_AVAILABLE_PERMISSIONS(state, available_permissions) {
    if (available_permissions) {
      state.available_permissions = available_permissions;
    }
  },
  SET_USER(state, user) {
    // Updates user used for modifying data
    state.user = {};
    state.user = Vue.util.extend({}, user); // Per a que no modifiqui el mateix element del array
  },
  ADD_USER(state, user) {
    // Adds a user to the list
    state.users.push(user);
  },
  MODIFY_USER(state, user) {
    // Updates user in list
    const user_to_modify = this.getters.getUserById(user.id);
    if (user_to_modify) {
      Object.assign(user_to_modify, user);
    }
  },
  SET_USER_VISIBILITY(state, data) {
    var user_to_modify = this.getters.getUserById(data.user_id);
    user_to_modify.active = data.active;
  },
  SET_USER_DATA(state, user) {
    state.user = {}; // Important to start with empty object
    Object.assign(state.user, user);
  },
  DELETE_USER(state, id) {
    const index = this.getters.getUserIndexById(id);
    if (index !== undefined) {
      state.users.splice(index, 1);
    }
  },
  SET_SHOW_CHANGE_PASSWORD(state, value) {
    state.show_change_password = value;
  }
};

export const actions = {
  fetchUsers({ commit }) {
    return UserService.getUsers()
      .then(response => {
        commit("GET_USERS", response.data.user);
        commit("SET_NEW_USER_MODEL", response.data.new_user_model);
        commit(
          "SET_AVAILABLE_PERMISSIONS",
          response.data.available_permissions
        );
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  fetchUser({ commit, getters }, id) {
    // Send in the getters
    var user = getters.getUserById(id); // See if we already have this user
    if (user) {
      // If we do, set the user
      commit("SET_USER", user);
    } else {
      // If not, get it with the API
      UserService.getUser(id)
        .then(response => {
          commit("SET_USER", response.data);
        })
        .catch(error => {
          // console.log("There was an error:", error.response);
        });
    }
  },
  createUser({ commit }, user) {
    return UserService.createUser(user).then(response => {
      commit("ADD_USER", response.data);
    });
  },
  // Guarda en servidor i actualiza state.user
  modifyUser({ commit }) {
    return UserService.modifyUser(this.state.user.user).then(response => {
      commit("SET_USER", response.data);
      commit("MODIFY_USER", response.data);
    });
  },
  updateUser({ commit }, user) {
    return UserService.modifyUser(user).then(response => {
      commit("MODIFY_USER", response.data);
    });
  },
  updateAppUser({ commit }, user) {
    return UserService.modifyAppUser(user).then(response => {
      commit("MODIFY_USER", response.data);
    });
  },
  setUserData({ commit }, user) {
    commit("SET_USER_DATA", user);
  },
  deleteUser({ commit }, id) {
    return UserService.deleteUser(id).then(() => {
      commit("DELETE_USER", id);
    });
  },
  //Changes current user's password
  changeUserPassword({ commit }, data) {
    return UserService.changePassword(
      this.state.user.user.id,
      data.new_password
    );
  },
  //Changes current user
  changeAppUserPassword({ commit }, data) {
    return UserService.changePassword(data.id, data.new_password);
  },
  activateUser({ commit }, id) {
    return UserService.activateUser(id).then(() => {
      commit("SET_USER_VISIBILITY", {
        user_id: id,
        active: true
      });
    });
  },
  deactivateUser({ commit }, id) {
    return UserService.deactivateUser(id).then(() => {
      commit("SET_USER_VISIBILITY", {
        user_id: id,
        active: false
      });
    });
  },
  setUserSettings({ commit, rootGetters }, settings) {
    return UserService.setUserSettings(
      rootGetters.current_user.id,
      settings
    ).then(response => {
      commit("SET_USER_SETTINGS", settings);
    });
  }
};

export const getters = {
  // Only id is a parameter, state is by default
  getUserById: state => id => {
    return state.users.find(user => user.id === id);
  },
  getUserIndexById: state => id => {
    return state.users.findIndex(user => user.id === id);
  },
  getAvailablePermissions: state => {
    let permissions = state.available_permissions;
    permissions["dashboard"] = true;
    permissions["settings"] = true;
    return permissions;
  },
  getUserSettings: (state, getters) => {
    return getters.current_user && getters.current_user.settings
      ? getters.current_user.settings
      : {};
  }
};
