import axios from "axios";
import store from "@/store/store.js";
import router from "@/router.js";
import params from "@/params.js";

export const environment = {
  api_url: "http://127.0.0.1:4010/api",
  headers: {
    // Accept: null,
    // "Content-Type": null,
    Authorization: localStorage.getItem("credentials")
      ? "Bearer " + JSON.parse(localStorage.getItem("credentials")).token
      : ""
  }
};

//Basic Clients settings
const apiClient = axios.create({
  baseURL: environment.api_url,
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// Token Authorization management
apiClient.interceptors.request.use(
  config => {
    if (config.method === "post") {
      delete config.headers.common["Accept"];
      delete config.headers.common["Content-Type"];
      delete config.headers["Accept"];
      delete config.headers["Content-Type"];
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default apiClient;
