import SpecialRatesService from "@/services/SpecialRatesService";
import { downloadFile } from "@/utils";
import Vue from "vue";

export const state = {
  table_data_status: 0,
  hoveredRate: undefined,
  specialRatesTableData: {},
  loadingTableData: true,
  page: 0,
  maxDays: 14,
  slide_status: 0,
  first_time: true,
  clicked_rate: null,
  windowItems: [],
  changesHistory: [],
  bulk_form_open: false,
  bulk_form_type: null,
  stop_sell_form_open: false,
  groups: []
};

export const mutations = {
  SET_DATASET_STATUS_SPECIAL_RATES(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET_SPECIAL_RATES(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_HOVERED_RATE_SPECIAL_RATES: (state, rateId) => {
    state.hoveredRate = rateId;
  },
  SET_TABLE_DATA_SPECIAL_RATES: (state, data) => {
    state.specialRatesTableData = { ...data };
  },
  SET_PAGE_SPECIAL_RATES: (state, page) => {
    state.page = page;
  },
  SET_MAX_DAYS_SPECIAL_RATES: (state, maxDays) => {
    state.maxDays = maxDays;
  },
  SET_SLIDE_STATUS_SPECIAL_RATES: (state, slide) => {
    state.slide_status = slide;
  },
  SET_FIRST_TIME_SPECIAL_RATES: (state, first_time) => {
    state.first_time = first_time;
  },
  SET_CLICKED_RATE_SPECIAL_RATES: (state, rate) => {
    state.clicked_rate = rate;
  },
  RESET_TABLE_DATA_ROW_VALUES_SPECIAL_RATES: state => {
    state.specialRatesTableData.rows.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        if (cell.price) {
          cell.price = cell.original_price;
        }
        if (cell.release) {
          cell.release = cell.original_release;
        }
        if (cell.stop_sell) {
          cell.stop_sell = cell.original_stop_sell;
        }
      });
    });
  },
  SET_LOADING_TABLE_DATA_SPECIAL_RATES: (state, loading) => {
    state.loadingTableData = loading;
  },
  PUSH_WINDOW_ITEMS_SPECIAL_RATES: (state, item) => {
    state.windowItems.push(item);
  },
  POP_WINDOW_ITEMS_SPECIAL_RATES: state => {
    state.windowItems.pop();
  },
  ADD_CHANGES_HISTORY_SPECIAL_RATES: (state, payload) => {
    state.changesHistory.push(payload);
  },
  RESET_CHANGES_HISTORY_SPECIAL_RATES: state => {
    state.changesHistory = [];
  },
  SET_BULK_FORM_OPEN_SPECIAL_RATES: (state, value) => {
    state.bulk_form_open = value;
  },
  SET_BULK_FORM_TYPE_SPECIAL_RATES: (state, value) => {
    state.bulk_form_type = value;
  },
  SET_STOP_SELL_FORM_OPEN_SPECIAL_RATES: (state, value) => {
    state.stop_sell_form_open = value;
  },
  SET_GROUPS_SPECIAL_RATES: (state, value) => {
    state.groups = value;
  }
};

export const actions = {
  updateSpecialRatesPage: ({ commit }, value) => {
    commit("SET_PAGE_SPECIAL_RATES", value);
  },
  updateSpecialRatesMaxDays: ({ commit }, value) => {
    commit("SET_MAX_DAYS_SPECIAL_RATES", value);
  },
  updateSpecialratesSlideStatus: ({ commit }, value) => {
    commit("SET_SLIDE_STATUS_SPECIAL_RATES", value);
  },
  setSpecialRatesHoveredRate: ({ commit }, value) => {
    commit("SET_HOVERED_RATE_SPECIAL_RATES", value);
  },
  setSpecialRatesClickedRate: ({ commit }, value) => {
    commit("SET_CLICKED_RATE_SPECIAL_RATES", value);
  },
  setSpecialRatesGroups: ({ commit }, value) => {
    commit("SET_GROUPS_SPECIAL_RATES", value);
  },
  resetSpecialRatesRowValues: ({ commit }) => {
    commit("RESET_TABLE_DATA_ROW_VALUES_SPECIAL_RATES");
    commit("RESET_CHANGES_HISTORY_SPECIAL_RATES");
  },
  updateSpecialRatesChangesHistory: ({ commit }, data) => {
    commit("ADD_CHANGES_HISTORY_SPECIAL_RATES", data);
  },
  setSpecialRatesBulkFormOpen: ({ commit }, value) => {
    commit("SET_BULK_FORM_OPEN_SPECIAL_RATES", value);
  },
  setSpecialRatesBulkFormType: ({ commit }, value) => {
    commit("SET_BULK_FORM_TYPE_SPECIAL_RATES", value);
  },
  setSpecialRatesStopSellFormOpen: ({ commit }, value) => {
    commit("SET_STOP_SELL_FORM_OPEN_SPECIAL_RATES", value);
  },
  applySpecialRatesChangesHistory: async ({ dispatch, state }) => {
    const currentHistoryLength = state.changesHistory.length;
    if (currentHistoryLength) {
      for (let i = 0; i < currentHistoryLength; i++) {
        const changeHistory = state.changesHistory[i];
        dispatch(changeHistory.action, {
          ...changeHistory.payload,
          doNotaddToHistory: true
        });
      }
    }
  },
  setSpecialRatesChanges: async (
    { state, rootGetters, commit, dispatch },
    payload
  ) => {
    // 1 send state.changesHistory to backend
    const data = {
      hotel_id: rootGetters.current_hotel.id,
      user_id: rootGetters.current_user.id,
      rate_id: payload.rate_id ? payload.rate_id : null,
      rate_name: payload.rate_name ? payload.rate_name : null,
      rate_description: payload.rate_description
        ? payload.rate_description
        : null,
      group_id: payload.group_id ? payload.group_id : null,
      changes: state.changesHistory.map(e => ({
        ...e,
        operation_name: e.payload.operation ? e.payload.operation.name : null
      }))
    };
    const response = await SpecialRatesService.postSpecialRatesChanges(data);

    // 2 clear state.changesHistory
    commit("RESET_CHANGES_HISTORY_SPECIAL_RATES");

    // 3 refetch data calling fetchSpecialratesTableData
    dispatch("fetchSpecialratesTableData");
  },
  applySpecialRatesPriceChanges: ({ commit, state }, payload) => {
    const rowsToEdit = state.specialRatesTableData.rows.filter(row =>
      payload.form.entity_ids.includes(row.rate_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          cell.price !== undefined &&
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.price = payload.daysSelected.includes((date.getUTCDay() + 6) % 7)
            ? payload.operation(payload.form.value, cell.price)
            : cell.price * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY_SPECIAL_RATES", {
        action: "applySpecialRatesPriceChanges",
        action_type: "price",
        payload
      });
    }
  },
  applySpecialRatesReleaseChanges: ({ commit, getters, state }, payload) => {
    const rowsToEdit = state.specialRatesTableData.rows.filter(row =>
      payload.form.entity_ids.includes(row.rate_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.release = payload.daysSelected.includes(
            (date.getUTCDay() + 6) % 7
          )
            ? payload.form.value
            : cell.original_release * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY_SPECIAL_RATES", {
        action: "applySpecialRatesReleaseChanges",
        action_type: "release",
        payload
      });
    }
  },
  applySpecialRatesStopSellChanges: ({ commit, getters, state }, payload) => {
    const rowsToEdit = state.specialRatesTableData.rows.filter(row =>
      payload.form.entity_ids.includes(row.rate_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.stop_sell = payload.daysSelected.includes(
            (date.getUTCDay() + 6) % 7
          )
            ? payload.form.value
            : cell.original_stop_sell * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY_SPECIAL_RATES", {
        action: "applySpecialRatesStopSellChanges",
        action_type: "stop_sell",
        payload
      });
    }
  },
  fetchSpecialRatesGroups: async ({ commit, rootGetters }) => {
    const dataset = "groups";
    commit("SET_DATASET_STATUS_SPECIAL_RATES", { dataset: dataset, status: 1 });
    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    const data = {
      hotel_id: rootGetters.current_hotel.id
    };
    await SpecialRatesService.getSpecialRatesGroups(data).then(response => {
      commit("GET_DATASET_SPECIAL_RATES", {
        dataset: dataset,
        data: response.data
      });
      commit("SET_DATASET_STATUS_SPECIAL_RATES", {
        dataset: dataset,
        status: 2
      });
    });
  },
  fetchSpecialratesTableData: async ({
    commit,
    state,
    rootGetters,
    dispatch
  }) => {
    let page = state.page;
    let maxDays = state.maxDays;
    const dataset = "table_data";

    if (state.first_time) {
      commit("SET_DATASET_STATUS_SPECIAL_RATES", {
        dataset: dataset,
        status: 1
      });
      commit("SET_FIRST_TIME_SPECIAL_RATES", false);
    } else {
      commit("SET_SLIDE_STATUS_SPECIAL_RATES", 0);
    }

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    try {
      const initialPage = state.page;
      const data = {
        hotel_id: rootGetters.current_hotel.id,
        page: page,
        columnsPerPage: maxDays
      };
      commit("SET_LOADING_TABLE_DATA_SPECIAL_RATES", true);
      const response = await SpecialRatesService.getSpecialRatesTableData(data);
      commit("SET_TABLE_DATA_SPECIAL_RATES", response.data);
      commit("SET_LOADING_TABLE_DATA_SPECIAL_RATES", false);
      commit("SET_PAGE_SPECIAL_RATES", page);

      const windowItemsMutation =
        initialPage > page
          ? "POP_WINDOW_ITEMS_SPECIAL_RATES"
          : "PUSH_WINDOW_ITEMS_SPECIAL_RATES";
      commit(windowItemsMutation, response.data.days);
      dispatch("applySpecialRatesChangesHistory");
      commit("SET_DATASET_STATUS_SPECIAL_RATES", {
        dataset: dataset,
        status: 2
      });
      commit("SET_SLIDE_STATUS_SPECIAL_RATES", 2);
      return response.data;
    } catch (e) {
      // console.error(e);
    }
  },
  downloadSpecialRates(data) {
    SpecialRatesService.downloadSpecialRates(data).then(response => {
      downloadFile(response, "SpecialRates.csv");
    });
  }
};

export const getters = {
  specialRatesTableHotelLabels: state => {
    return (
      state.specialRatesTableData && state.specialRatesTableData.hotelLabels
    );
  },
  specialRatesChangesPending: state => {
    return state.changesHistory.length > 0;
  }
};
