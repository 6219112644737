<template>
  <div id="hotels-resume-app">
    <v-row class="py-0">
      <v-col cols="8" class="pb-0"> </v-col>
      <v-col cols="4" class="pb-0">
        <v-text-field
          ref="search"
          class="right-align"
          v-model="search"
          rounded
          outlined
          dense
          clearable
          solo
          flat
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('search_hotel_advanced')"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="list-group">
      <hotel-list-item
        v-for="hotel in first_level_hotels"
        :hotel="hotel"
        :level="0"
        :currencies="currencies"
        :key="hotel.id"
        :search="search"
      >
      </hotel-list-item>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import hotelListItem from "@/components/hotelListItem";

export default {
  components: {
    hotelListItem
  },
  data: () => ({
    search: ""
  }),
  methods: {
    handleKeydown(event) {
      const search = this.$refs.search;
      const validKey = /^[a-zA-Z0-9]$/;
      if (
        document.activeElement !== search.$el.querySelector("input") &&
        validKey.test(event.key) &&
        !event.metaKey
      ) {
        search.focus();
        this.search += event.key;
        event.preventDefault();
      }
    }
  },
  watch: {
    show_hotel_list: {
      handler: function(val) {
        if (val) {
          window.addEventListener("keydown", this.handleKeydown);
        } else {
          window.removeEventListener("keydown", this.handleKeydown);
        }
      },
      immediate: true
    }
  },
  computed: {
    userHotels() {
      // Filter just user hotels
      // return this.hotels.filter(hotel => {
      //   return current_user.hotels.find(hotel.id) ? true : false;
      // })
      return this.hotels;
    },
    first_level_hotels() {
      return this.hotels.filter(hotel => hotel.first_level == true);
    },
    ...mapState({
      hotels: state => state.hotel.hotels,
      currencies: state => state.currency.currencies,
      hotel_selected: state => state.hotel.hotel,
      show_hotel_list: state => state.hotel.show_hotel_list
    })
  }
};
</script>
