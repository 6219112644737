import OffersService from "@/services/OffersService";
import { downloadFile } from "@/utils";
import Vue from "vue";

export const state = {
  table_data_status: 0,
  hoveredRate: undefined,
  offersTableData: {},
  loadingTableData: true,
  page: 0,
  maxDays: 14,
  slide_status: 0,
  first_time: true,
  clicked_rate: null,
  windowItems: [],
  changesHistory: [],
  bulk_form_open: false,
  bulk_form_type: null,
  stop_sell_form_open: false,
  groups: []
};

export const mutations = {
  SET_DATASET_STATUS_OFFERS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET_OFFERS(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_HOVERED_RATE_OFFERS: (state, rateId) => {
    state.hoveredRate = rateId;
  },
  SET_TABLE_DATA_OFFERS: (state, data) => {
    state.offersTableData = { ...data };
  },
  SET_PAGE_OFFERS: (state, page) => {
    state.page = page;
  },
  SET_MAX_DAYS_OFFERS: (state, maxDays) => {
    state.maxDays = maxDays;
  },
  SET_SLIDE_STATUS_OFFERS: (state, slide) => {
    state.slide_status = slide;
  },
  SET_FIRST_TIME_OFFERS: (state, first_time) => {
    state.first_time = first_time;
  },
  SET_CLICKED_RATE_OFFERS: (state, rate) => {
    state.clicked_rate = rate;
  },
  RESET_TABLE_DATA_ROW_VALUES_OFFERS: state => {
    state.offersTableData.rows.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        if (cell.offer) {
          cell.offer = cell.original_offer;
        }
        if (cell.release) {
          cell.release = cell.original_release;
        }
        if (cell.stop_sell) {
          cell.stop_sell = cell.original_stop_sell;
        }
      });
    });
  },
  SET_LOADING_TABLE_DATA_OFFERS: (state, loading) => {
    state.loadingTableData = loading;
  },
  PUSH_WINDOW_ITEMS_OFFERS: (state, item) => {
    state.windowItems.push(item);
  },
  POP_WINDOW_ITEMS_OFFERS: state => {
    state.windowItems.pop();
  },
  ADD_CHANGES_HISTORY_OFFERS: (state, payload) => {
    state.changesHistory.push(payload);
  },
  RESET_CHANGES_HISTORY_OFFERS: state => {
    state.changesHistory = [];
  },
  SET_BULK_FORM_OPEN_OFFERS: (state, value) => {
    state.bulk_form_open = value;
  },
  SET_BULK_FORM_TYPE_OFFERS: (state, value) => {
    state.bulk_form_type = value;
  },
  SET_STOP_SELL_FORM_OPEN_OFFERS: (state, value) => {
    state.stop_sell_form_open = value;
  },
  SET_GROUPS_OFFERS: (state, value) => {
    state.groups = value;
  }
};

export const actions = {
  updateOffersPage: ({ commit }, value) => {
    commit("SET_PAGE_OFFERS", value);
  },
  updateOffersMaxDays: ({ commit }, value) => {
    commit("SET_MAX_DAYS_OFFERS", value);
  },
  updateOffersSlideStatus: ({ commit }, value) => {
    commit("SET_SLIDE_STATUS_OFFERS", value);
  },
  setOffersHoveredRate: ({ commit }, value) => {
    commit("SET_HOVERED_RATE_OFFERS", value);
  },
  setOffersClickedRate: ({ commit }, value) => {
    commit("SET_CLICKED_RATE_OFFERS", value);
  },
  setOffersGroups: ({ commit }, value) => {
    commit("SET_GROUPS_OFFERS", value);
  },
  resetOffersRowValues: ({ commit }) => {
    commit("RESET_TABLE_DATA_ROW_VALUES_OFFERS");
    commit("RESET_CHANGES_HISTORY");
  },
  updateOffersChangesHistory: ({ commit }, data) => {
    commit("ADD_CHANGES_HISTORY_OFFERS", data);
  },
  setOffersBulkFormOpen: ({ commit }, value) => {
    commit("SET_BULK_FORM_OPEN_OFFERS", value);
  },
  setOffersBulkFormType: ({ commit }, value) => {
    commit("SET_BULK_FORM_TYPE_OFFERS", value);
  },
  setOffersStopSellFormOpen: ({ commit }, value) => {
    commit("SET_STOP_SELL_FORM_OPEN_OFFERS", value);
  },
  applyOffersChangesHistory: async ({ dispatch, state }) => {
    const currentHistoryLength = state.changesHistory.length;
    if (currentHistoryLength) {
      for (let i = 0; i < currentHistoryLength; i++) {
        const changeHistory = state.changesHistory[i];
        dispatch(changeHistory.action, {
          ...changeHistory.payload,
          doNotaddToHistory: true
        });
      }
    }
  },
  setOffersChanges: async (
    { state, rootGetters, commit, dispatch },
    payload
  ) => {
    // 1 send state.changesHistory to backend
    const data = {
      hotel_id: rootGetters.current_hotel.id,
      user_id: rootGetters.current_user.id,
      offer_id: payload.offer_id ? payload.offer_id : null,
      offer_name: payload.offer_name ? payload.offer_name : null,
      offer_description: payload.offer_description
        ? payload.offer_description
        : null,
      offer_mode: payload.offer_mode ? payload.offer_mode : null,
      group_id: payload.group_id ? payload.group_id : null,
      changes: state.changesHistory.map(e => ({
        ...e,
        operation_name: e.payload.operation ? e.payload.operation.name : null
      }))
    };
    const response = await OffersService.postOffersChanges(data);

    // 2 clear state.changesHistory
    commit("RESET_CHANGES_HISTORY_OFFERS");

    // 3 refetch data calling fetchOffersTableData
    dispatch("fetchOffersTableData");
  },
  applyOffersPriceChanges: ({ commit, state }, payload) => {
    const rowsToEdit = state.offersTableData.rows.filter(row =>
      payload.form.entity_ids.includes(row.offer_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          cell.offer !== undefined &&
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.offer = payload.daysSelected.includes((date.getUTCDay() + 6) % 7)
            ? payload.operation(payload.form.value, cell.offer)
            : cell.offer * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY_OFFERS", {
        action: "applyOffersPriceChanges",
        action_type: "price",
        payload
      });
    }
  },
  applyOffersReleaseChanges: ({ commit, getters, state }, payload) => {
    const rowsToEdit = state.offersTableData.rows.filter(row =>
      payload.form.entity_ids.includes(row.offer_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.release = payload.daysSelected.includes(
            (date.getUTCDay() + 6) % 7
          )
            ? payload.form.value
            : cell.original_release * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY_OFFERS", {
        action: "applyOffersReleaseChanges",
        action_type: "release",
        payload
      });
    }
  },
  applyOffersStopSellChanges: ({ commit, getters, state }, payload) => {
    const rowsToEdit = state.offersTableData.rows.filter(row =>
      payload.form.entity_ids.includes(row.offer_id)
    );
    rowsToEdit.forEach(row => {
      Object.keys(row.days).forEach(day => {
        const cell = row.days[day];
        const date = Vue.moment(day).toDate();
        if (
          date >= Vue.moment(payload.datesRange[0]).toDate() &&
          date <= Vue.moment(payload.datesRange[1]).toDate()
        ) {
          cell.stop_sell = payload.daysSelected.includes(
            (date.getUTCDay() + 6) % 7
          )
            ? payload.form.value
            : cell.original_stop_sell * 1;
        }
      });
    });
    if (!payload.doNotaddToHistory) {
      commit("ADD_CHANGES_HISTORY_OFFERS", {
        action: "applyOffersStopSellChanges",
        action_type: "stop_sell",
        payload
      });
    }
  },
  fetchOffersGroups: async ({ commit, rootGetters }) => {
    const dataset = "groups";
    commit("SET_DATASET_STATUS_OFFERS", { dataset: dataset, status: 1 });
    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    const data = {
      hotel_id: rootGetters.current_hotel.id
    };
    await OffersService.getOffersGroups(data).then(response => {
      commit("GET_DATASET_OFFERS", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS_OFFERS", { dataset: dataset, status: 2 });
    });
  },
  fetchOffersTableData: async ({ commit, state, rootGetters, dispatch }) => {
    let page = state.page;
    let maxDays = state.maxDays;
    const dataset = "table_data";

    if (state.first_time) {
      commit("SET_DATASET_STATUS_OFFERS", { dataset: dataset, status: 1 });
      commit("SET_FIRST_TIME_OFFERS", false);
    } else {
      commit("SET_SLIDE_STATUS_OFFERS", 0);
    }

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    try {
      const initialPage = state.page;
      const data = {
        hotel_id: rootGetters.current_hotel.id,
        page: page,
        columnsPerPage: maxDays
      };
      commit("SET_LOADING_TABLE_DATA_OFFERS", true);
      const response = await OffersService.getOffersTableData(data);
      commit("SET_TABLE_DATA_OFFERS", response.data);
      commit("SET_LOADING_TABLE_DATA_OFFERS", false);
      commit("SET_PAGE", page);

      const windowItemsMutation =
        initialPage > page
          ? "POP_WINDOW_ITEMS_OFFERS"
          : "PUSH_WINDOW_ITEMS_OFFERS";
      commit(windowItemsMutation, response.data.days);
      dispatch("applyOffersChangesHistory");
      commit("SET_DATASET_STATUS_OFFERS", { dataset: dataset, status: 2 });
      commit("SET_SLIDE_STATUS_OFFERS", 2);
      return response.data;
    } catch (e) {
      // console.error(e);
    }
  },
  downloadOffers(data) {
    OffersService.downloadOffers(data).then(response => {
      downloadFile(response, "Offers.csv");
    });
  }
};

export const getters = {
  offersTableHotelLabels: state => {
    return state.offersTableData && state.offersTableData.hotelLabels;
  },
  offersChangesPending: state => {
    return state.changesHistory.length > 0;
  }
};
