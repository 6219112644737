import Vue from "vue";
import TrialService from "@/services/TrialService.js";

export const state = {
  // status
  requests_status: 0,
  // dataset
  requests: []
};

export const mutations = {
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  }
};

export const actions = {
  fetchTrialRequests({ commit, rootGetters }, p) {
    const dataset = "requests";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // No Required params

    TrialService.getRequests().then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  changeTrialExternalId({ commit }, data) {
    return TrialService.changeExternalId(data.id, data.external_id);
  },
  changeTrialComments({ commit }, data) {
    return TrialService.changeComments(data.id, data.comments);
  },
  setTrialContactDone({ commit }, data) {
    return TrialService.setTrialContactDone(data.id);
  },
  setTrialContactPending({ commit }, data) {
    return TrialService.setTrialContactPending(data.id);
  },
  setTrialEnabled({ commit }, data) {
    return TrialService.setTrialEnabled(data.id);
  },
  setTrialDisabled({ commit }, data) {
    return TrialService.setTrialDisabled(data.id);
  },
  deleteTrial({ commit }, data) {
    return TrialService.deleteTrial(data.id);
  },
  changeTrialConfiguration({ commit }, data) {
    return TrialService.changeConfiguration(
      data.id,
      data.hotel_booking_url,
      data.competitor_booking_urls
    );
  },
  addTrial({ commit }, data) {
    return TrialService.addTrial(data.fullname, data.email, data.hotel_url);
  }
};

export const getters = {};
