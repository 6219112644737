import Vue from "vue";
import ParityService from "@/services/ParityService.js";

export const state = {
  // status
  webs_status: 0,
  booking_basic_status: 0,
  channels_status: 0,
  available_dates_status: 0,
  links_status: 0,

  // dataset
  webs: [],
  booking_basic: [],
  channels: [],
  invisible_channels: [],
  available_dates: [], // Dates with data and its last update date
  links: []
};

export const mutations = {
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  ADD_INVISIBLE_CHANNEL(state, payload) {
    state.invisible_channels.push(payload);
  },
  REMOVE_INVISIBLE_CHANNEL(state, payload) {
    let index = state.invisible_channels.indexOf(payload);
    state.invisible_channels.splice(index, 1);
  },
  EMPTY_INVISIBLE_CHANNEL(state, payload) {
    state.invisible_channels = [];
  }
};

export const actions = {
  fetchParityWebs({ commit, rootGetters }, p) {
    const dataset = "webs";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let hotel_id = rootGetters.current_hotel.id;
    ParityService.getWebs(hotel_id).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchParityBookingBasic({ commit, rootGetters }, p) {
    const dataset = "booking_basic";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let hotel_id = rootGetters.current_hotel.id;
    ParityService.getBookingBasic(hotel_id).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchParityChannels({ commit, rootGetters }, p) {
    const dataset = "channels";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let hotel_id = rootGetters.current_hotel.id;
    ParityService.getChannels(hotel_id).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchParityAvailableDates({ commit, rootState, rootGetters }, p) {
    const dataset = "available_dates";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      extraction_id: rootState.extraction_id
    };
    ParityService.getAvailableDates(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchParityLinks({ commit, rootState, rootGetters }, p) {
    const dataset = "links";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !rootState.extraction_id ||
      !rootState.pax
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      extraction_id: rootState.extraction_id,
      adults: rootState.pax
    };
    ParityService.getParityChannelLinks(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  addVisibleChannel({ commit }, v) {
    commit("ADD_INVISIBLE_CHANNEL", v);
  },
  removeVisibleChannel({ commit }, v) {
    commit("REMOVE_INVISIBLE_CHANNEL", v);
  },
  emptyInvisibleChannel({ commit }, v) {
    commit("EMPTY_INVISIBLE_CHANNEL", v);
  }
};
