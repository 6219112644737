import DataImportService from "@/services/DataImportService";
import { downloadFile } from "@/utils.js";
import BudgetService from "@/services/BudgetService";

export const state = {
  // status
  revenue_dates_status: 0,
  revenue_dates_calendar_status: 0,
  revenue_last_imports_status: 0,
  revenue_daily_columns_status: 0,
  revenue_reservation_columns_status: 0,
  // dataset
  revenue_dates: [],
  revenue_dates_calendar: [],
  revenue_last_imports: [],
  revenue_daily_columns: [],
  revenue_reservation_columns: [],
  // data
  revenue_dates_calendar_dimension: null,
  revenue_dates_calendar_show_full_year: false,
  hotelsdot_available_imports: null
};

export const mutations = {
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_REVENUE_DATES_CALENDAR_DIMENSION(state, v) {
    state.revenue_dates_calendar_dimension = v;
  },
  SET_REVENUE_DATES_CALENDAR_SHOW_FULL_YEAR(state, v) {
    state.revenue_dates_calendar_show_full_year = v;
  },
  SET_HOTELSDOT_AVAILABLE_IMPORTS(state, v) {
    state.hotelsdot_available_imports = v;
  }
};

export const actions = {
  fetchDataimportRevenueDates({ commit, rootGetters }) {
    const dataset = "revenue_dates";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    return DataImportService.getImportDatesStatus({
      hotel_id: rootGetters.current_hotel.id
    }).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchDataimportRevenueDatesCalendar({ commit, rootGetters, state }) {
    const dataset = "revenue_dates_calendar";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    return DataImportService.getImportDatesCalendar({
      hotel_id: rootGetters.current_hotel.id,
      dimension: state.revenue_dates_calendar_dimension
    }).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchDataimportRevenueLastImports({ commit, rootGetters }) {
    const dataset = "revenue_last_imports";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    return DataImportService.getRevenueLastImports({
      hotel_id: rootGetters.current_hotel.id
    }).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchDataimportRevenueDailyColumns({ commit, rootGetters }) {
    const dataset = "revenue_daily_columns";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    return DataImportService.getImportDailyColumns({
      hotel_id: rootGetters.current_hotel.id
    }).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchDataimportRevenueReservationColumns({ commit, rootGetters }) {
    const dataset = "revenue_reservation_columns";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    return DataImportService.getImportReservationColumns({
      hotel_id: rootGetters.current_hotel.id
    }).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchHotelsdotAvailableImports({ commit }, data) {
    return DataImportService.getHotelsdotAvailableImports(data).then(
      response => {
        commit("SET_HOTELSDOT_AVAILABLE_IMPORTS", response);
      }
    );
  },
  importManualRevenue({ commit, dispatch }, data) {
    return DataImportService.importManualRevenue(data.formData);
  },
  startAutomaticImport({ commit, dispatch }, data) {
    return DataImportService.startAutomaticImport(data);
  },
  importUlysesRevenue({ commit, dispatch }, data) {
    return DataImportService.importUlysesRevenue(data);
  },
  migrateHotelsdotImports({ commit, dispatch }, data) {
    return DataImportService.migrateHotelsdotImports(data);
  },
  downloadRevenueImportTemplate({ commit }, data) {
    DataImportService.downloadRevenueImportTemplate(data).then(response => {
      downloadFile(response, "Import Template " + data.hotel_name + ".xlsx");
    });
  },
  updateRevenueDailyColumn({ commit, dispatch }, data) {
    return DataImportService.updateRevenueDailyColumn(data);
  },
  updateRevenueReservationColumn({ commit, dispatch }, data) {
    return DataImportService.updateRevenueReservationColumn(data);
  },
  setRevenueDatesCalendarDimension({ commit }, v) {
    commit("SET_REVENUE_DATES_CALENDAR_DIMENSION", v);
  },
  setRevenueDatesCalendarShowFullYear({ commit }, v) {
    commit("SET_REVENUE_DATES_CALENDAR_SHOW_FULL_YEAR", v);
  },
  downloadImportFile({ commit }, data) {
    DataImportService.downloadImportFile(data).then(response => {
      downloadFile(response, data.file);
    });
  },
  deleteImport({ commit, dispatch }, data) {
    return DataImportService.deleteImport(data);
  },
  fetchImportDateDetails({ commit, dispatch }, data) {
    return DataImportService.getImportDateDetails(data);
  },
  downloadImportData({ commit }, data) {
    DataImportService.downloadImportData(data).then(response => {
      downloadFile(
        response,
        data.hotel_id +
          "_" +
          data.import_date +
          (data.dimension ? "_" + data.dimension : "") +
          ".xlsx"
      );
    });
  }
};

export const getters = {};
