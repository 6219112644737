// Obtenemos el customer
const host_parts = window.location.host.split(".");
const customer = host_parts[0];

var params = {};
if (customer == "localhost:8080") {
  params = require("./customers/default/params.js");
} else {
  try {
    // Buscamos si existe la carpeta con la configuración para el customer
    params = require("./customers/" + customer + "/params.js");
  } catch (e) {
    // Si no existe, definimos las rutas usando el customer
    const curr_domain = host_parts.length > 1 ? host_parts[1] : "";
    const domain = curr_domain == "veetal" ? "veetal.app" : "hawgbi.com";
    params = {
      default: {
        auth_url: "https://" + customer + "-api." + domain + "/auth",
        api_url: "https://" + customer + "-api." + domain + "/api"
      }
    };
  }
}

export default {
  auth_url: params.default.auth_url,
  api_url: params.default.api_url
};
