import apiClient from "./Environment";

export default {
  getReport(data) {
    return apiClient.post("/get-report", {
      hotel_id: data.hotel_id,
      vs: data.vs,
      hotel_vs: data.hotel_vs,
      year: data.year,
      month: data.month,
      otb_option: data.otb_option
    });
  },
  getDailyReport(data) {
    return apiClient.post("/get-daily-report", {
      hotel_id: data.hotel_id,
      vs: data.vs,
      hotel_vs: data.hotel_vs,
      year: data.year,
      month: data.month,
      otb_option: data.otb_option
    });
  },
  getReputationAnsweredByTime(data) {
    return apiClient.post("/get-reputation-answered-by-time", {
      hotel_id: data.hotel_id,
      vs: data.vs,
      hotel_vs: data.hotel_vs,
      year: data.year,
      month: data.month
    });
  },
  getReputationReviews(data) {
    return apiClient.post("/get-reputation-reviews", {
      hotel_id: data.hotel_id,
      year: data.year,
      month: data.month
    });
  }
};
