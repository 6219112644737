import MetricsService from "@/services/MetricsService.js";

export const state = {
  import_task_id: null,
  vs_import_task_id: null,
  available_imports: [],
  metrics: [],
  vs_metrics: []
};

export const mutations = {
  SET_IMPORT_TASK_ID(state, import_task_id) {
    state.import_task_id = import_task_id;
  },
  SET_VS_IMPORT_TASK_ID(state, vs_import_task_id) {
    state.vs_import_task_id = vs_import_task_id;
  },
  SET_AVAILABLE_IMPORTS(state, available_imports) {
    state.available_imports = available_imports;
  },
  SET_METRICS(state, metrics) {
    state.metrics = metrics;
  },
  SET_VS_METRICS(state, metrics) {
    state.vs_metrics = metrics;
  }
};

export const actions = {
  fetchAvailableImports({ commit, rootGetters }) {
    MetricsService.getAvailableImports({
      hotel_id: rootGetters.current_hotel.id
    })
      .then(response => {
        commit("SET_AVAILABLE_IMPORTS", response.data);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  setMetricsImportTaskId({ commit }, import_task_id) {
    commit("SET_IMPORT_TASK_ID", import_task_id);
  },
  setMetricsVsImportTaskId({ commit }, vs_import_task_id) {
    commit("SET_VS_IMPORT_TASK_ID", vs_import_task_id);
  },
  fetchMetrics({ commit, rootGetters }) {
    MetricsService.getMetrics({
      hotel_id: rootGetters.current_hotel.id,
      import_task_id: state.import_task_id
    })
      .then(response => {
        commit("SET_METRICS", response.data);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  fetchVsMetrics({ commit, rootGetters }) {
    MetricsService.getMetrics({
      hotel_id: rootGetters.current_hotel.id,
      import_task_id: state.vs_import_task_id
    })
      .then(response => {
        commit("SET_VS_METRICS", response.data);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  }
};

export const getters = {
  getMetricsAvailableImports: state => {
    return state.available_imports;
  }
};
