<template>
  <router-view></router-view>
</template>

<script>
import Vuex from "vuex";

export default {
  created() {}
};
</script>
