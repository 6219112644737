import Vue from "vue";
// import HotelService from "@/services/HotelService.js";

export const state = {
  currencies: ["eur"],
  currency: "€"
};

export const mutations = {};

export const actions = {};

export const getters = {};
