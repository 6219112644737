import apiClient from "./Environment";
import * as Utils from "@/utils.js";

export default {
  getRoomTypesMapped(data) {
    return apiClient.get("/get-room-types-mapped", { params: data });
  },
  getRoomTypesAvailable(data) {
    return apiClient.get("/get-all-room-types-available", { params: data });
  },
  generateRoomTypesMappingsFromPms(data) {
    return apiClient.post("/generate-room-types-mappings-pms", data);
  },
  generateRoomTypesMappingsFromChannelManager(data) {
    return apiClient.post(
      "/generate-room-types-mappings-channel-manager",
      data
    );
  },
  generateRoomTypesMappingsFromBooking(data) {
    return apiClient.post("/generate-room-types-mappings-booking", data);
  },
  generateRoomTypesMappingsFromWeb(data) {
    return apiClient.post("/generate-room-types-mappings-web", data);
  },
  setRoomType(data) {
    return apiClient
      .post("/set-room-type", data)
      .then(response => response.data);
  },
  removeRoomType(data) {
    return apiClient
      .post("/remove-room-type", data)
      .then(response => response.data);
  },
  highlightRoomType(data) {
    return apiClient
      .post("/highlight-room-rate", data)
      .then(response => response.data);
  },
  setRoomTypePosition(data) {
    return apiClient
      .post("/set-room-type-position", data)
      .then(response => response.data);
  },
  getRoomTypePeriods(data) {
    return apiClient
      .get("/get-room-rate-periods" + Utils.buildGetParams(data))
      .then(response => response.data);
  },
  setRoomRatePeriods(data) {
    return apiClient
      .post("/set-room-rate-periods", data)
      .then(response => response.data);
  }
};
