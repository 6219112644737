<template>
  <div v-if="show_hotel" class="mt-1" style="position: relative;">
    <v-btn
      v-if="isGroup && !hotel.first_level"
      fab
      absolute
      small
      depressed
      bordered
      color="white"
      style="left:-20px; top: 10px;border:2px solid #e0e0e0 !important;"
      :class="{ 'rotate-right': !opened }"
      @click="opened = !opened"
      ><v-icon color="grey">mdi-chevron-down</v-icon></v-btn
    >
    <v-hover v-slot:default="{ hover }">
      <v-card
        @click.stop="setCurrentHotel(hotel)"
        dense
        v-blur
        class="hotel-component"
        style="cursor: pointer;"
        :color="isActive || hover ? 'blue lighten-5' : ''"
        :flat="!hover && !isActive"
        v-ripple="{ class: `blue--text lighten-2` }"
      >
        <v-row class="mr-0">
          <v-col class="py-0 pr-0 d-flex alidn-center" cols="12" md="3">
            <div
              class="v-list-item no-click"
              :style="
                isGroup && !hotel.first_level ? 'padding-left: 35px;' : ''
              "
            >
              <v-list-item-avatar
                v-if="hotel.settings && hotel.settings.is_chain"
                style="margin-top:6px; margin-bottom: 6px !important;"
              >
                <v-img :src="chain.logo"></v-img>
              </v-list-item-avatar>
              <template v-else>
                <v-btn
                  v-if="!isActive"
                  fav
                  rounded
                  :color="hotel.settings.color"
                  class="v-list-item__avatar subtitle-2 white--text text-uppercase justify-center "
                  style="height: 40px; min-width: 40px; width: 40px;margin-top:6px; margin-bottom: 6px !important;"
                  @click="setCurrentHotel(hotel)"
                  :elevation="isLeaf ? 0 : 5"
                  >{{ hotel.settings.abbreviation }}
                </v-btn>
                <v-btn
                  v-else
                  rounded
                  color="info"
                  class="v-list-item__avatar title white--text text-uppercase justify-center"
                  style="height: 40px; min-width: 40px; width: 40px;margin-top:6px; margin-bottom:6px !important;"
                  ><v-icon color="white">mdi-check</v-icon>
                </v-btn>
              </template>
              <div class="v-list-item__content py-2 ">
                <div
                  class="v-list-item__title subtitle-2"
                  :class="{ 'text-uppercase': isGroup }"
                >
                  {{ hotel.name }}
                </div>
                <div>
                  <v-rating
                    v-if="isLeaf"
                    color="primary"
                    readonly
                    dense
                    small
                    class="mt-n1"
                    :value="hotel.settings.stars ? hotel.settings.stars : 0"
                  ></v-rating>
                  <div
                    v-else-if="hotel.first_level"
                    class="v-list-item_title grey--text subtitle-2 mt-n1"
                    :class="{ 'text-uppercase': isGroup }"
                  >
                    {{ $t("chain") }}
                  </div>
                  <div
                    v-else
                    class="v-list-item__title grey--text subtitle-2 mt-n1"
                    :class="{ 'text-uppercase': isGroup }"
                  >
                    {{ $t("group") }}
                  </div>
                </div>
                <!--  -->
              </div>
            </div>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="9"
            v-if="$vuetify.breakpoint.smAndUp && !isGroup"
          >
            <v-row>
              <v-col cols="12" class="py-2" v-if="hotel.nodata">
                <div class="col-xs-2 text-center hidden-xs data-forecast">
                  <v-chip
                    class="ma-2"
                    color="indigo darken-3"
                    outlined
                    small
                    v-if="hotel.type === 'hotel'"
                  >
                    - {{ $t("without_data_this_month") }} -
                  </v-chip>
                  <div v-else>
                    <div
                      v-if="hotel.location.city"
                      class="v-list-item__subtitle grey--text"
                    >
                      <b>{{ hotel.location.city }}</b> ({{
                        hotel.location.country
                      }})
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="py-2" v-else>
                <v-row>
                  <v-col
                    v-for="kpi in kpis"
                    :key="kpi"
                    class="justify-center text-center py-0"
                  >
                    <div v-if="hasSpots(hotel.resume_data[kpi])">
                      <div>
                        <span class="overline grey--text pr-2">
                          {{ $t(kpi) }}
                        </span>
                      </div>
                      <div class="subtitle-2">
                        <a-kpi-columns
                          :kpi="hotel.resume_data[kpi].kpi_code"
                          :value="hotel.resume_data[kpi].otb"
                          :vs_values="[
                            hotel.resume_data[kpi].stly,
                            hotel.resume_data[kpi].bud,
                            hotel.resume_data[kpi].ly
                          ]"
                          :vs_periods="['stly', 'bud', 'ly']"
                          :locale="
                            get_language_by_locale(
                              hotel.settings.locale,
                              hotel.settings.locale_decimals
                            )
                          "
                          :vertical="$vuetify.breakpoint.lgAndDown"
                          small
                        ></a-kpi-columns>
                      </div>
                    </div>
                    <div v-else>
                      <span class="overline grey--text">{{ $t(kpi) }}</span
                      ><br />
                      <span class="subtitle-2">
                        <a-kpi
                          :kpi="kpi"
                          :value="hotel.resume_data[kpi]"
                          :increment="
                            hotel.resume_data['inc_' + kpi] ? true : false
                          "
                          :increment_value="hotel.resume_data['inc_' + kpi]"
                          clas=""
                        ></a-kpi>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
    <v-expand-transition>
      <div
        v-show="child_hotels.length > 0 && opened"
        class="ml-6 mb-4 grey lighten-4"
      >
        <hotel-list-item
          v-for="h in child_hotels"
          :hotel="h"
          :level="level + 1"
          :key="h.id"
          :search="search"
        >
        </hotel-list-item>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/rms_ui/aKpi";
import AKpiColumns from "@/components/rms_ui/aKpiColumns.vue";

export default {
  components: {
    AKpiColumns,
    aKpi
  },
  name: "hotel-list-item",
  data: () => ({
    opened: true
  }),
  props: ["hotel", "level", "search"],
  methods: {
    setCurrentHotel(hotel) {
      this.$store.dispatch("setCurrentHotel", hotel);
      this.show_hotel_list = false;
      return false;
    },
    hasSpots(kpi) {
      return kpi && kpi.otb !== undefined;
    },
    hotelMatchesSearch(hotel) {
      return (
        hotel.name.toLowerCase().includes(this.search.toLowerCase()) ||
        (hotel.location &&
          hotel.location.city &&
          hotel.location.city.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    hotelOrChildrenMatchesSearch(hotel) {
      return (
        this.hotelMatchesSearch(hotel) ||
        this.childrenMatchingSearch(hotel).length > 0
      );
    },
    childrenMatchingSearch(hotel) {
      return this.hotels.filter(
        h => h.father_id === hotel.id && this.hotelOrChildrenMatchesSearch(h)
      );
    }
  },
  watch: {},
  computed: {
    isLeaf() {
      return this.hotel.children_number === 0;
    },
    isGroup() {
      return this.hotel.children_number > 0;
    },
    isActive() {
      return this.current_hotel.id === this.hotel.id;
    },
    kpis() {
      return this.hotel.resume_data ? Object.keys(this.hotel.resume_data) : [];
    },
    child_hotels() {
      let hotels = this.hotels.filter(h => h.father_id === this.hotel.id);
      if (this.search && this.search.length > 0) {
        hotels = hotels.filter(
          c =>
            this.hotelMatchesSearch(this.hotel) ||
            this.hotelOrChildrenMatchesSearch(c)
        );
      }
      // sort hotels array by group / position / name
      hotels.sort((a, b) => {
        const hasChildrenA = a.children_number > 0;
        const hasChildrenB = b.children_number > 0;
        const positionA = a.settings.position;
        const positionB = b.settings.position;

        if (hasChildrenA !== hasChildrenB) return hasChildrenA ? -1 : 1;
        if (positionA !== positionB && positionA && positionB)
          return parseInt(positionA) < parseInt(positionB) ? -1 : 1;
        return a.name.localeCompare(b.name);
      });
      return hotels;
    },
    show_hotel() {
      return !(this.isGroup && this.child_hotels.length === 0);
    },
    show_hotel_list: {
      get() {
        return this.$store.state.hotel.show_hotel_list;
      },
      set(value) {
        this.$store.dispatch("setShowHotelList", value);
      }
    },
    ...mapState({
      chain: state => state.chain.chain,
      hotels: state => state.hotel.hotels,
      currencies: state => state.currency.currencies,
      currency: state => state.currency.currency
      // current_hotel: state => state.hotel.hotel
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      get_language_by_locale: "get_language_by_locale"
    })
  },
  created() {}
};
</script>
<style scoped>
.rotate-right .v-icon {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-90deg);
  display: inline-block;
}
</style>
